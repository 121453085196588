import Ship from "@/helpers/ships/ship";
import shipUtils from "@/helpers/ships/ship_utils";
const SHIP_ADITIONAL_HEADING_ADJUSTMENT = -90; //Ships in AIS need to turn arround full 180º

/**
 * Ship abstraction that contains several parts:
 *
 * *
 *    E
 *    /\
 *   /  \
 *  /    \
 * A------B
 * |      |
 * |      |
 * |      |
 * |      |
 * | ___  |
 * ||1|2| |
 * ||_|_| |
 * D\----/C
 *  F--Z--G
 *
 *
 *
 * - Hull (main ship part)
 * - ISPS Code (1)
 * - Dangerous Cargo (2)
 *
 *
 * In this case, this ship can be a circle (if invalid hdg is on)
 */

import debounce from "lodash/debounce";
export default class VCShip extends Ship {
  constructor(
    geometries,
    opts,
    specification,
    mooringBittsManager,
    zonesManager
  ) {
    super(geometries, opts, specification, mooringBittsManager, zonesManager);

    // The idea is simple:
    // while dragging a ship, in order to maintain mooring bitts, we need to check
    // if an adicional key is being pressed. If so, do not change mooring location but only move the ship
    // freely.
    this.on(
      "dragstart",
      debounce(() => {
        // Keep the current specification, before dragging
        this.previousSpecification = JSON.parse(
          JSON.stringify(this.shipSpecification)
        );

        // Keep the value of the alt key when dropping the ship
        if (this.shipSpecification) {
          this.shipSpecification._absolutePositioning = this._isAltKeyPressed;
        }
      })
    );

    this.on(
      "dragging",
      debounce((evt) => {
        if (this.shipSpecification) {
          this.shipSpecification._absolutePositioning = this._isAltKeyPressed;
        }

        let pointerCoordinates = [evt.coordinate.x, evt.coordinate.y];
        let dragShadow = evt.target.draggable._shadow;
        if (dragShadow) {
          let shadowGeoms = dragShadow.getGeometries();

          // Drawing is fixed for VCShip:
          // shadowGeoms[3] - Bow Bitt
          // shadowGeoms[4] - Stern Bitt
          let bowShipBitt = shadowGeoms[3];
          let sternShipBitt = shadowGeoms[4];

          this._suggestConnectors(bowShipBitt, sternShipBitt);
        }
        this._suggestZone(pointerCoordinates);
      })
    );

    this.on(
      "dragend",
      debounce(() => {
        if (this.mooringBittsManager) {
          this.mooringBittsManager.resetStyle();
        }
        if (this.zonesManager) {
          this.zonesManager.resetStyle();
        }
        // Keep the value of the alt key when dropping the ship
        if (this.shipSpecification) {
          this.shipSpecification._absolutePositioning = this._isAltKeyPressed;
        }
        this.fire("position_updating", {
          target: this,
        });
        this._updateConnectors();
      }, 300)
    );

    this.on("ship_highlighted", () => {
      document.removeEventListener("keydown", this.onKeyDown.bind(this), true);
      document.removeEventListener("keyup", this.onKeyUp.bind(this), true);

      setTimeout(() => {
        document.addEventListener("keydown", this.onKeyDown.bind(this), true);
        document.addEventListener("keyup", this.onKeyUp.bind(this), true);
      }, 100);
    });

    this.on("ship_dehighlighted", () => {
      document.removeEventListener("keydown", this.onKeyDown.bind(this), true);
      document.removeEventListener("keyup", this.onKeyUp.bind(this), true);
    });
  }

  onKeyDown(evt) {
    this._isAltKeyPressed = evt.altKey;

    // Rotate Ship
    if (evt && this._isAltKeyPressed && evt.key == "+") {
      if (this.highlighted) {
        // Keep the current specification, before rotating
        this.previousSpecification = JSON.parse(
          JSON.stringify(this.shipSpecification)
        );

        this.shipSpecification.hdg = shipUtils.reduceAngle(
          this.shipSpecification.hdg + 5
        );

        this.fire("heading_updated", { target: this });
      }
    }

    if (evt && this._isAltKeyPressed && evt.key == "-") {
      if (this.highlighted) {
        // Keep the current specification, before rotating
        this.previousSpecification = JSON.parse(
          JSON.stringify(this.shipSpecification)
        );

        this.shipSpecification.hdg = shipUtils.reduceAngle(
          this.shipSpecification.hdg - 5
        );
        this.Heading = this.shipSpecification.hdg;
        this.fire("heading_updated", { target: this });
      }
    }
  }

  onKeyUp() {
    this._isAltKeyPressed = false;
  }

  setShipSpec(spec) {
    // Fix a bug in AIS, where ships are turned arround.
    spec.COMPENSATE_HEADING = SHIP_ADITIONAL_HEADING_ADJUSTMENT;
    super.setShipSpec(spec);
  }

  /**
   * Removes (if any) highlight of the current ship
   *
   */
  removeHighlight() {
    if (this.hull && this.highlighted) {
      let style = shipUtils.getVCShipStyle(this.shipSpecification);
      this.hull.updateSymbol(style.symbol);
      this.highlighted = false;

      this.fire("ship_dehighlighted", {
        id: this.properties.id,
        ship: this,
      });
    }
  }
}
