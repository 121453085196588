<template></template>

<script>
import { VectorLayer, Geometry } from "maptalks";

export default {
  props: ["map", "annotations"],
  mounted() {
    this.loadPreviousAnnotations();
  },
  methods: {
    //load previous annotations saved in dab
    loadPreviousAnnotations() {
      this.map.getLayers().forEach((l) => {
        if (l._id.includes("Previous")) l.remove();
      });

      if (this.annotations) {
        this.annotations.forEach((annotation) => {
          if (!!annotation && !!annotation.id && !!annotation.json) {
            let previousAnnotationsLayer = null;
            let layerId = "Previous" + annotation.id.replace("Previous", ""); //purge previous tag

            if (!this.map.getLayer(layerId)) {
              previousAnnotationsLayer = new VectorLayer(layerId).addTo(
                this.map
              );
            } else {
              previousAnnotationsLayer = this.map.getLayer(layerId);
            }

            let annotationGeom = Geometry.fromJSON(annotation.json);
            annotationGeom.addTo(previousAnnotationsLayer);
            previousAnnotationsLayer.show();
          }
        });
      }
    },
  },
};
</script>
