<template></template>

<script>
//Access vue instance to reach global constants
import * as maptalks from "maptalks";

//LAYER_NAMES to be accessed anywhere on this component
const VCSHIPS_LAYERNAME = "vc_ships";
const MOORINGBITTS_LAYERNAME = "mooring_bitts";
const MOORING_ROPES_LAYERNAME = "mooring_ropes";
const ZONES_LAYERNAME = "zones";

export default {
  props: ["map", "layers"],
  mounted() {
    this.loadLayers();
  },
  methods: {
    loadLayers() {
      this.layers.forEach((layer) => {
        this.showLayer(layer);
      });
    },
    getLayerObject(layer) {
      if (layer) {
        let layerName = "bg_" + layer.id;
        let backgroundMapLayer = this.map.getLayer(layerName);
        let layerDefinition = JSON.parse(layer.content);
        if (!backgroundMapLayer) {
          if (layer.type == "WMS") {
            return new maptalks.WMSTileLayer(layerName, {
              urlTemplate: layer.source_url,
              crs: layerDefinition.crs,
              layers: layerDefinition.layers,
              format: layerDefinition.format,
              transparent: true,
              uppercase: false,
              forceRenderOnMoving: true,
              forceRenderOnZooming: true,
              forceRenderOnRotating: true,
            }).addTo(this.map);
          }
        } else {
          return backgroundMapLayer;
        }
      }
    },
    showLayer(layer) {
      if (!layer) return;
      let bgLayer = this.getLayerObject(layer);
      bgLayer.show();
      this.rearrangeLayers();
    },

    rearrangeLayers() {
      var currentLayers = this.map.getLayers();
      /**
       * Ordering of layers follows this visualization rule, from top to bottom:
       *
       * SHIPS
       * MOORINGBITTS
       * MOORING_ROPES,
       * LAYERS
       * BASELAYERS
       */
      if (currentLayers) {
        let sortedList = [];
        let orderTiers = {
          MEASURES: [],
          MOORING_ROPES: [],
          MOORING_BITTS: [],
          CELLS: [],
          ZHS: [],
          ZONES: [],
          SHIPS: [],
          LAYERS: [],
        };
        currentLayers.forEach((l) => {
          switch (l._id) {
            case (l._id.match(/^distancetool_/) || {}).input:
              orderTiers.MEASURES.push(l);
              break;
            case ZONES_LAYERNAME:
              orderTiers.ZONES.push(ZONES_LAYERNAME);
              break;
            case MOORING_ROPES_LAYERNAME:
              orderTiers.MOORING_ROPES.push(MOORING_ROPES_LAYERNAME);
              break;
            case MOORINGBITTS_LAYERNAME:
              orderTiers.MOORING_BITTS.push(MOORINGBITTS_LAYERNAME);
              break;
            case VCSHIPS_LAYERNAME:
              orderTiers.SHIPS.push(l._id);
              break;
            default:
              if (l && l.options && l.options._originalLayer) {
                orderTiers.LAYERS.push({
                  _id: l._id,
                  order_num: l.options._originalLayer.order_num,
                });
              } else {
                orderTiers.LAYERS.push({
                  _id: l._id,
                  order_num: 9,
                });
              }
              break;
          }
        });
        let orderedLayers = orderTiers.LAYERS.sort((a, b) => {
          return b.order_num - a.order_num; //Ascending
        }).map((l) => {
          return l._id;
        });
        sortedList.push(...orderedLayers);
        sortedList.push(...orderTiers.ZONES);
        sortedList.push(...orderTiers.MOORING_ROPES);
        sortedList.push(...orderTiers.MOORING_BITTS);
        sortedList.push(...orderTiers.SHIPS);
        sortedList.push(...orderTiers.MEASURES);
        this.map.sortLayers(sortedList);
      }
    },
  },
};
</script>
