import DispatcherEvent from "@/helpers/dispatcherevent";
import zone_utils from "@/helpers/map/zone_utils";

class ZonesManager {
  constructor() {
    this.events = {};
    this.zones = new Map();
  }

  clearZones() {
    this.zones.clear();
  }

  getZones() {
    return this.zones;
  }

  getZoneById(id) {
    return this.zones.get(id);
  }

  getZoneByCode(code) {
    for (let value of this.zones) {
      let zone = value[1];
      if (zone.Code == code) {
        return zone;
      }
    }
    return null;
  }

  zoneExists(id) {
    return this.zones.has(id);
  }

  addZones(zones) {
    if (zones) {
      zones.forEach((zone) => {
        this.addZone(zone);
      });
    }
  }

  addZone(zone) {
    if (this.zoneExists(zone.Id)) {
      this.updateZone(zone);
    } else {
      this.zones.set(zone.Id, zone);
      this.dispatch("zone_added", zone);
    }
  }

  updateZone(zone) {
    if (this.zoneExists(zone.Id)) {
      this.zones.set(zone.Id, zone);
      this.dispatch("zone_updated", zone);
    }
  }

  clearAll() {
    if (this.zones) {
      for (let value of this.zones) {
        this.dispatch("zone_removed", value[1]);
      }
      this.clearZones();
    }
  }

  /**
   * Global event mechanism
   */
  dispatch(eventName, data) {
    const event = this.events[eventName];
    if (event) {
      event.fire(data);
    }
  }

  on(eventName, callback) {
    let event = this.events[eventName];
    if (!event) {
      event = new DispatcherEvent(eventName);
      this.events[eventName] = event;
    }
    event.registerCallback(callback);
  }

  off(eventName, callback) {
    const event = this.events[eventName];
    if (event && event.callbacks.indexOf(callback) > -1) {
      event.unregisterCallback(callback);
      if (event.callbacks.length === 0) {
        delete this.events[eventName];
      }
    }
  }

  resetStyle() {
    if (this.zones) {
      for (let value of this.zones) {
        let zone = value[1];
        if (zone && zone._drawnObject) {
          zone._drawnObject.updateSymbol(zone_utils.getZoneStyle());
        }
      }
    }
  }
}

export default ZonesManager;
