<template>
  <div></div>
</template>

<script>
//Access vue instance to reach global constants
import * as maptalks from "maptalks";

const VCSHIPS_LAYERNAME = "vc_ships";
const DEFAULT_SHIP_LENGTH = 20;
const DEFAULT_SHIP_WIDTH = 5;
const MOORING_ROPES_LAYERNAME = "mooring_ropes";

import VCShip from "@/helpers/ships/vcship";
import mooringBittUtils from "@/helpers/map/mooringbitt_utils";
import * as turf from "@turf/turf";
import { Settings, DateTime } from "luxon";
import configs from "@/helpers/configs";

Settings.defaultZone = configs.getTimezone();
Settings.defaultLocale = configs.getLocale();

import debounce from "lodash/debounce";
const STATUSES = configs.getVesselCallStatuses();

export default {
  props: {
    refMap: Object,
    item: Object,
    draggable: Boolean,
    records: Array,
    mooringBittsManager: Object,
    zonesManager: Object,
    performedActions: Array,
    showBuffer: Boolean,
    archived: { type: Boolean, default: false },
  },
  data() {
    return {
      selected: null,
      loading: false,
      drawnShips: new Map(),
    };
  },
  computed: {
    actions: {
      get() {
        return this.performedActions;
      },
      set(actions) {
        this.$emit("update:performedActions", actions);
      },
    },
    shipTypes() {
      return this.$store.state.shiptypes.shipTypesList;
    },
    vesselCall: {
      get() {
        return this.item;
      },
      set(val) {
        this.$emit("update:item", val);
      },
    },
    map: {
      get() {
        return this.refMap;
      },
      set(val) {
        this.$emit("update:refMap", val);
      },
    },
    vesselCalls: {
      get() {
        return this.records;
      },
      set(val) {
        this.$emit("update:records", val);
      },
    },

    mooringBitts() {
      return this.$store.state.moorings.mooringsList;
    },

    zones() {
      return this.$store.state.zones.zonesList;
    },

    isAnnotationsEnabled() {
      return this.$store.state.plans.annotationsEnabled;
    },
  },
  watch: {
    vesselCall(val) {
      //clear all previous selected
      this.drawnShips.forEach((ship) => {
        if (!!ship._drawnObject) ship._drawnObject.removeHighlight();
      });
      if (val && this.drawnShips.has(val.id)) {
        //highlight this ship
        this.drawnShips.get(val.id)._drawnObject.addHighlight();

        //center map in this ship
        this.refMap.setCenter(
          this.drawnShips.get(val.id)._drawnObject.getShipCenterOrigin()
        );

        //fix zoom
        this.refMap.setZoom(18);
      }
    },
    vesselCalls() {
      this.recreateLayer();
    },
    draggable() {
      this.updateAllVesselCallsDraggableState();
    },
    isAnnotationsEnabled() {
      this.recreateLayer();
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.clearLayer();
  },
  methods: {
    init() {
      this.recreateLayer();
    },

    clearLayer() {
      if (this.map) {
        this.drawnShips.clear();
        if (this.map.getLayer(VCSHIPS_LAYERNAME))
          this.map.getLayer(VCSHIPS_LAYERNAME).clear();
        if (this.vesselCalls) {
          this.vesselCalls.forEach((vc) => delete vc._drawnObject);
        }

        if (this.mooringBittsManager) {
          this.mooringBittsManager.resetStyle(true /* hard reset*/);
        }
        if (this.actions) {
          this.actions = [];
        }
      }
    },

    getShipTypeByCode(code) {
      let shipType = null;
      if (this.shipTypes) {
        shipType = this.shipTypes.find((t) => t.code == code);
      }
      return shipType;
    },

    recreateLayer() {
      if (this.mooringBittsManager) {
        this.mooringBittsManager.resetStyle(true /* hard reset*/);
      }
      this.loading = true;
      if (this.map) {
        if (!this.map.getLayer(VCSHIPS_LAYERNAME))
          this.map.addLayer(new maptalks.VectorLayer(VCSHIPS_LAYERNAME));
        if (!this.map.getLayer(MOORING_ROPES_LAYERNAME))
          this.map.addLayer(new maptalks.VectorLayer(MOORING_ROPES_LAYERNAME));
      }
      this.clearLayer();
      if (this.vesselCalls) {
        this.vesselCalls.forEach((vc) => {
          this.handleShipUpdated(vc);
        });
      }

      this.updateDraw();
      this.loading = false;
    },

    updateDraw() {
      this.rearrangeOverlapping();
    },

    handleShipAdded(ship) {
      if (ship) {
        if (!ship._drawnObject) {
          let shipSpec = this.mapShipToSpec(ship);

          let shipType = this.getShipTypeByCode(shipSpec.TypeName);
          let fillColor = "#ccc",
            lineColor = "#ccc";
          if (shipType) {
            lineColor = shipType.style.lineColor;
            fillColor = shipType.style.fillColor;
          }
          try {
            let drawnShip = new VCShip(
              [],
              {
                properties: {
                  id: shipSpec.Id,
                  name: shipSpec.Name,
                  lineColor: lineColor,
                  fillColor: fillColor,
                  width: shipSpec.Width,
                  length: shipSpec.Length,
                  lat: shipSpec.Lat
                    ? parseFloat(shipSpec.Lat).toFixed(8)
                    : null,
                  lon: shipSpec.Lon
                    ? parseFloat(shipSpec.Lon).toFixed(8)
                    : null,
                  hdg: parseFloat(shipSpec.Heading),
                  flag: shipSpec.Flag,
                  dateStr: shipSpec.Date,
                  color: shipSpec.Color,
                  polygonOpacity: 1,
                  lineDasharray:
                    shipSpec.Status == "EXPECTED_ARRIVAL_VESSEL_CALLS"
                      ? [5, 5]
                      : null,
                },
                draggable: shipSpec.Draggable,
                visible: shipSpec.Visible,
              },
              shipSpec,
              this.mooringBittsManager,
              this.zonesManager
            );
            if (this.mooringBittsManager) {
              let mooringBowBitt = null;
              let mooringSternBitt = null;
              let ropeBow = null;
              let ropeStern = null;
              if (shipSpec.BowMooringBitt && shipSpec.BowMooringBitt.Code) {
                mooringBowBitt = this.mooringBittsManager.getMooringBittByCode(
                  shipSpec.BowMooringBitt.Code
                );
                if (mooringBowBitt) {
                  ropeBow = mooringBittUtils.drawBowMooringRope(
                    drawnShip,
                    mooringBowBitt._drawnObject
                  );
                  drawnShip.setBowConnector(ropeBow);
                  ropeBow.addTo(this.map.getLayer(MOORING_ROPES_LAYERNAME));
                }
              }
              if (shipSpec.SternMooringBitt && shipSpec.SternMooringBitt.Code) {
                mooringSternBitt =
                  this.mooringBittsManager.getMooringBittByCode(
                    shipSpec.SternMooringBitt.Code
                  );
                if (mooringSternBitt) {
                  ropeStern = mooringBittUtils.drawSternMooringRope(
                    drawnShip,
                    mooringSternBitt._drawnObject
                  );
                  drawnShip.setSternConnector(ropeStern);
                  ropeStern.addTo(this.map.getLayer(MOORING_ROPES_LAYERNAME));
                }
              }
            }

            drawnShip.on("ship_selected", (e) => {
              if (this.hasUserPermissionToViewEditOrManage("PLANS"))
                this.vesselCall = this.vesselCalls.find((element) => {
                  return element.id == e.id;
                });
            });

            drawnShip.on(
              "position_updating",
              debounce((event) => {
                if (this.actions) {
                  this.actions.push({
                    id: event.target.shipSpecification.id,
                    shipSpecification: event.target.previousSpecification,
                  });
                }
              }, 250)
            );

            drawnShip.on(
              "heading_updated",
              debounce((event) => {
                if (this.actions) {
                  this.actions.push({
                    id: event.target.shipSpecification.id,
                    shipSpecification: event.target.previousSpecification,
                  });
                }

                //Update the vessel calls list
                this.updateVesselCall(
                  event.target,
                  true /** reset validations **/
                );

                this.updateDraw();
              }, 250)
            );

            drawnShip.on(
              "position_updated",
              debounce((event) => {
                this.updateVesselCall(
                  event.target,
                  true /** reset validations **/
                );

                this.updateDraw();
              }, 250)
            );

            if (drawnShip) {
              drawnShip.addTo(this.map.getLayer(VCSHIPS_LAYERNAME));
              ship._drawnObject = drawnShip;
              this.drawnShips.set(shipSpec.Id, ship);
            }
          } catch (e) {
            console.error(e, shipSpec);
          }
        } else {
          this.handleShipUpdated(ship);
        }
      } else {
        console.warn(
          "Not enough info to draw...",
          ship.name,
          " --- ",
          ship.berth_location
        );
      }
    },

    handleShipUpdated(ship) {
      if (ship) {
        if (ship._drawnObject) {
          let shipSpec = this.mapShipToSpec(ship);
          ship._drawnObject.updateShipSpec(shipSpec);
          this.drawnShips.set(shipSpec.Id, ship);
        } else {
          //this was wrongly assumed as an update, but we dont have it yet
          this.handleShipAdded(ship);
        }
      }
    },

    handleShipRemoved(ship) {
      if (ship && ship._drawnObject) {
        ship._drawnObject.remove();
        this.drawnShips.delete(ship.Id);
      }
    },

    updateAllVesselCallsDraggableState() {
      if (this.drawnShips) {
        let ships = Array.from(this.drawnShips.values());
        ships.forEach((v) => {
          if (v && v._drawnObject) {
            let properties = v._drawnObject.getProperties();
            Object.assign(properties, { draggable: this.draggable });
            v._drawnObject.setProperties(properties);
            v._drawnObject.config("draggable", this.draggable);
          }
        });
      }
    },

    updateVesselCall(ship, resetValidations = false) {
      // find the vessel call that this update refers to:
      let vesselCall = this.vesselCalls.find((vc) => {
        return vc.process_number == ship.shipSpecification.process_number;
      });

      if (vesselCall) {
        if (ship.shipSpecification && ship.shipSpecification.bowMooringBitt) {
          vesselCall.end_mooring_bow =
            ship.shipSpecification.bowMooringBitt.Code ?? null;
        } else {
          vesselCall.end_mooring_bow = null;
        }
        if (ship.shipSpecification && ship.shipSpecification.sternMooringBitt) {
          vesselCall.end_mooring_stern =
            ship.shipSpecification.sternMooringBitt.Code ?? null;
        } else {
          vesselCall.end_mooring_stern = null;
        }

        vesselCall.berth_location =
          ship.shipSpecification.berth_location ?? null;
        vesselCall.upcoming_berth_location = vesselCall.berth_location;

        vesselCall.lat =
          ship.shipSpecification &&
          ship.shipSpecification.coordinates &&
          ship.shipSpecification.coordinates.length > 0
            ? ship.shipSpecification.coordinates[1]
            : vesselCall.lat;
        vesselCall.lon =
          ship.shipSpecification &&
          ship.shipSpecification.coordinates &&
          ship.shipSpecification.coordinates.length > 0
            ? ship.shipSpecification.coordinates[0]
            : vesselCall.lon;

        vesselCall.hdg = ship.shipSpecification.hdg ?? 0;
        vesselCall._absolutePositioning =
          ship.shipSpecification._absolutePositioning ?? false;
        vesselCall._showCoords = ship.shipSpecification._showCoords ?? false;

        if (resetValidations) {
          //Clear vessel call validations
          vesselCall.dates_status = null;
          vesselCall.draught_status = null;
          vesselCall.location_status = null;
        }
      }
    },

    _getShipBufferedLine(ship) {
      let units = { units: "meters" };
      if (ship && ship._drawnObject) {
        let shipBowConnector = ship._drawnObject.getBowConnector();
        let shipSternConnector = ship._drawnObject.getSternConnector();
        let shipBowTarget,
          shipSternTarget,
          shipBowCoordinates,
          shipSternCoordinates;
        if (shipBowConnector && shipSternConnector) {
          shipBowTarget = shipBowConnector.getConnectTarget();
          shipSternTarget = shipSternConnector.getConnectTarget();
          if (shipBowTarget && shipSternTarget) {
            shipBowCoordinates = shipBowTarget.getCoordinates();
            shipSternCoordinates = shipSternTarget.getCoordinates();

            let mooringBittsLine = turf.lineString([
              [shipBowCoordinates.x, shipBowCoordinates.y],
              [shipSternCoordinates.x, shipSternCoordinates.y],
            ]);
            // Get mooring bitts length, to cut in both ends
            let mooringBittsLineLength = turf.length(mooringBittsLine, units);

            let shipLine = turf.lineSliceAlong(
              mooringBittsLine,
              10,
              mooringBittsLineLength - 10,
              units
            );
            let buffer = turf.buffer(shipLine, 4, {
              units: "meters",
              steps: 1,
            });

            //uncomment to show buffer line
            /*maptalks.GeoJSON.toGeometry(buffer).addTo(
              this.refMap.getLayer(MOORING_ROPES_LAYERNAME)
            );*/

            return buffer;
          }
        }
      }
    },

    rearrangeOverlapping() {
      if (this.drawnShips) {
        let ships = Array.from(this.drawnShips.values()).filter(
          (s) => s.visible
        );

        ships.forEach((v) => {
          if (v && v._drawnObject) {
            v._drawnObject.resetBump();
          }
        });

        ships.forEach((comparingShip) => {
          if (comparingShip && comparingShip._drawnObject) {
            let shipStatus = comparingShip.status;
            let shipEta = DateTime.fromISO(comparingShip.eta);
            let shipEtd = DateTime.fromISO(comparingShip.etd);

            let shipsToCompare = ships.map((s) => {
              return { ...s };
            });
            shipsToCompare = shipsToCompare.filter((s) => {
              if (s.process_number == comparingShip.process_number)
                return false;
              if (s.status !== shipStatus) return false;
              if (s.status == "EXPECTED_ARRIVAL_VESSEL_CALLS") {
                if (DateTime.fromISO(s.eta) > shipEta) return false;
              } else {
                if (DateTime.fromISO(s.etd) > shipEtd) return false;
              }

              return true;
            });
            let comparingShipBufferedLine =
              this._getShipBufferedLine(comparingShip);

            shipsToCompare.forEach((compareToShip) => {
              if (
                compareToShip.process_number !== comparingShip.process_number
              ) {
                let compareToShipBufferedLine =
                  this._getShipBufferedLine(compareToShip);

                if (
                  comparingShipBufferedLine &&
                  compareToShipBufferedLine &&
                  turf.booleanIntersects(
                    comparingShipBufferedLine,
                    compareToShipBufferedLine
                  )
                ) {
                  // if this is true, we need to bump comparingShip
                  if (comparingShip.status == "EXPECTED_ARRIVAL_VESSEL_CALLS") {
                    //ETA are the same
                    if (comparingShip.eta == compareToShip.eta) {
                      if (compareToShip._drawnObject._rearranged) {
                        comparingShip._drawnObject.bumpOrder(
                          compareToShip.beam
                        );
                      }
                    } else {
                      comparingShip._drawnObject.bumpOrder(compareToShip.beam);
                    }
                  } else {
                    //ETD are the same
                    if (comparingShip.etd == compareToShip.etd) {
                      if (compareToShip._drawnObject._rearranged) {
                        comparingShip._drawnObject.bumpOrder(
                          compareToShip.beam
                        );
                      }
                    } else {
                      comparingShip._drawnObject.bumpOrder(compareToShip.beam);
                    }
                  }

                  //check if intersect
                  this.updateVesselCall(comparingShip._drawnObject);
                }
              }
            });

            comparingShip._drawnObject._rearranged = true;
          }
        });

        ships.forEach((v) => {
          if (v && v._drawnObject) {
            let _drawnObject = v._drawnObject;
            //updates the rotation of a symbol based on ship heading
            if (_drawnObject && v._drawnObject.shipSpecification.hdg) {
              let textRotation = 0;
              let hdgAux =
                parseFloat(v._drawnObject.shipSpecification.hdg + 90) % 360;

              if (hdgAux > 180)
                textRotation =
                  -parseFloat(v._drawnObject.shipSpecification.hdg) + 180;
              else
                textRotation = -parseFloat(
                  v._drawnObject.shipSpecification.hdg
                );

              let symbol = _drawnObject.getSymbol();
              symbol.children[1].textRotation = textRotation;
              _drawnObject.setSymbol(symbol);
            }
          }
        });
      }
    },

    mapShipToSpec(ship) {
      let eta = "N/D",
        etd = "N/D";

      if (ship.eta) {
        eta = new Date(ship.eta);
        eta = DateTime.fromJSDate(eta).toFormat("dd/LL/yyyy HH:mm");
      }
      if (ship.etd) {
        etd = new Date(ship.etd);
        etd = DateTime.fromJSDate(etd).toFormat("dd/LL/yyyy HH:mm");
      }

      let status = STATUSES.find((data) => data.code == ship.status);

      return {
        Id: ship.id,
        Temporary: ship.temporary,
        Name: ship.name,
        IMO: ship.imo,
        ProcessNumber: ship.process_number,
        Draggable: this.draggable,
        Visible: ship.visible && (ship.show != null ? ship.show : true),
        Length: ship.loa ? parseFloat(ship.loa) : DEFAULT_SHIP_LENGTH,
        Width: ship.beam ? parseFloat(ship.beam) : DEFAULT_SHIP_WIDTH,
        MapGeom:
          ship.lon && ship.lat
            ? "POINT(" + ship.lon + " " + ship.lat + ")"
            : null,
        Heading: ship.hdg ? parseFloat(ship.hdg) : 0,
        Flag: ship.flag,
        TypeName: ship.type,
        Status: ship.status,
        Date: "ETA: " + eta + "\nETD: " + etd,
        Color: status ? status.color : "#FF0000",
        BowMooringBitt: { Code: ship.end_mooring_bow },
        SternMooringBitt: { Code: ship.end_mooring_stern },
        BerthLocation: ship.upcoming_berth_location
          ? ship.upcoming_berth_location
          : ship.berth_location,
        ISPS: true,
        Hazmat: ship.has_dangerous_good,
        InvertVector: ship.status == "EXPECTED_ARRIVAL_VESSEL_CALLS",
        AbsolutePositioning: ship._absolutePositioning ?? false,
        ShowCoords: this.isAnnotationsEnabled,
        BufferBow: ship.buffer_bow != null ? parseFloat(ship.buffer_bow) : 0,
        BufferStern:
          ship.buffer_stern != null ? parseFloat(ship.buffer_stern) : 0,
        BufferPortside:
          ship.buffer_portside != null ? parseFloat(ship.buffer_portside) : 0,
        BufferStarboard:
          ship.buffer_starboard != null ? parseFloat(ship.buffer_starboard) : 0,
        ShowBuffer: this.showBuffer,
        Starboard: ship.manoeuvre_starboard,
        Lon: ship.lon
          ? parseFloat(ship.lon).toFixed(8)
          : this.map.getCenter().y,
        Lat: ship.lat
          ? parseFloat(ship.lat).toFixed(8)
          : this.map.getCenter().x,
      };
    },
  },
};
</script>
