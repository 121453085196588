<template>
  <div></div>
</template>

<script>
const ZONES_LAYERNAME = "zones";
import ZonesUtils from "@/helpers/map/zone_utils";
import * as maptalks from "maptalks";

export default {
  props: ["refMap", "zonesManager", "records", "portLocode"],
  computed: {
    manager: {
      get() {
        return this.zonesManager;
      },
      set(val) {
        this.$emit("update:zonesManager", val);
      },
    },
    map: {
      get() {
        return this.refMap;
      },
      set(val) {
        this.$emit("update:refMap", val);
      },
    },
    zones() {
      return JSON.parse(JSON.stringify(this.$store.state.zones.zonesList));
    },
  },
  mounted() {
    this.enable();
    if (!this.portLocode) this.loadZones();
    else this.loadPublicZones();
  },
  watch: {
    zones() {
      this.processZones();
    },
  },
  beforeDestroy() {
    this.disable();
  },
  methods: {
    enable() {
      this.recreateLayers();
      this.zonesManager.on("zone_added", this.handleZoneAdded);
      this.zonesManager.on("zone_updated", this.handleZoneUpdated);
      this.zonesManager.on("zone_removed", this.handleZoneRemoved);
    },
    disable() {
      this.zonesManager.off("zone_added", this.handleZoneAdded);
      this.zonesManager.off("zone_updated", this.handleZoneUpdated);
      this.zonesManager.off("zone_removed", this.handleZoneRemoved);
      this.clearLayers();
    },

    clearLayers() {
      this.zonesManager.clearAll();
      if (this.map) {
        if (this.map.getLayer(ZONES_LAYERNAME))
          this.map.getLayer(ZONES_LAYERNAME).clear();
      }
    },
    recreateLayers() {
      if (this.map) {
        if (!this.map.getLayer(ZONES_LAYERNAME)) {
          this.map.addLayer(new maptalks.VectorLayer(ZONES_LAYERNAME));
        } else {
          this.clearLayers();
        }
      }
    },

    handleZoneAdded(zone) {
      let drawnZone = ZonesUtils.drawZone(zone);
      if (drawnZone) {
        zone._drawnObject = drawnZone;
        drawnZone.addTo(this.map.getLayer(ZONES_LAYERNAME));
      }
    },

    handleZoneRemoved(obj) {
      if (obj && obj._drawnObject) {
        obj._drawnObject.remove();
      }
    },
    handleZoneUpdated(obj) {
      this.handleZoneAdded(obj);
      this.handleZoneRemoved(obj);
    },

    loadZones() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("zones/GET_ZONES")
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    loadPublicZones() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("zones/GET_PUBLIC_ZONES", this.portLocode)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    mapZone(zone) {
      return {
        Id: zone.id,
        Code: zone.code,
        Name: zone.name,
        GeoJSON: zone.geojson,
        Unavailable: zone.unavailable,
        Notes: zone.notes,
      };
    },

    processZones() {
      this.clearLayers();
      if (this.zones) {
        let zones = this.zones.map(this.mapZone);
        this.zonesManager.addZones(zones);

        this.$emit("loaded");
      }
    },

    createChart(dom, code, width, height, factor) {
      var chart = echarts.init(dom);

      var option = {
        maintainAspectRatio: false,
        title: {
          text: code,
          x: "center",
          y: "middle",
          textStyle: {
            fontSize: 12 * factor,
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        color: this.getChartColors(code),
        series: [
          {
            name: code,
            type: "pie",
            radius: [width, height],
            center: ["50%", "50%"],
            data: this.getChartData(code),
            label: {
              normal: {
                show: true,
                formatter: "({c}) {b}",
                fontSize: 10 * factor,
                fontWeight: "bold",
                textBorderWidth: 0,
                color: "#000",
              },
            },
          },
        ],
      };
      chart.setOption(option);
      return chart;
    },
  },
};
</script>
