<template>
  <SharedMap :key="compKey" />
</template>

<script>
import SharedMap from "@/components/Public/Map";

export default {
  components: {
    SharedMap,
  },
  data() {
    return {
      compKey: 0,
    };
  },
  computed: {
    screenshotId() {
      return this.$route.params.id;
    },
  },
  watch: {
    screenshotId(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.compKey += 1;
      }
    },
  },
};
</script>
