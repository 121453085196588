<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        class="py-5"
        @click="resetView"
        color="white"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon color="primary">mdi-map-marker-radius</v-icon>
      </v-btn>
    </template>
    <span> {{ $t("global.resetView") }} </span>
  </v-tooltip>
</template>

<script>
const DEFAULT_PITCH = 0;
export default {
  props: ["map", "portLocode"],
  computed: {
    refMap() {
      return this.map;
    },
  },
  methods: {
    resetView() {
      if (this.refMap) {
        this.refMap.setView({
          center: this.portLocode.coordinates,
          zoom: this.portLocode.zoom,
          pitch: DEFAULT_PITCH,
          bearing: this.portLocode.bearing,
        });
      }
    },
  },
};
</script>
<style scoped>
.v-btn--active::before {
  opacity: 0;
}
</style>
