<template>
  <div></div>
</template>

<script>
const LAYERNAME = "mooring_bitts";
import mooringBittUtils from "@/helpers/map/mooringbitt_utils";
//Access vue instance to reach global constants
import * as maptalks from "maptalks";

export default {
  props: ["refMap", "mooringBittsManager", "portLocode"],
  computed: {
    manager: {
      get() {
        return this.mooringBittsManager;
      },
      set(val) {
        this.$emit("update:mooringBittsManager", val);
      },
    },
    map: {
      get() {
        return this.refMap;
      },
      set(val) {
        this.$emit("update:refMap", val);
      },
    },
    mooringBitts() {
      return JSON.parse(
        JSON.stringify(this.$store.state.moorings.mooringsList)
      );
    },
  },
  mounted() {
    this.enable();
    if (!this.portLocode) this.loadMooringBitts();
    else this.loadPublicMooringBitts();
  },
  watch: {
    mooringBitts() {
      this.processMooringBitts();
    },
  },
  beforeDestroy() {
    this.disable();
  },
  methods: {
    enable() {
      this.recreateLayers();
      this.mooringBittsManager.clearAll();

      this.mooringBittsManager.on(
        "mooring_bitt_added",
        this.handleMooringBittAdded
      );
      this.mooringBittsManager.on(
        "mooring_bitt_updated",
        this.handleMooringBittUpdated
      );
      this.mooringBittsManager.on(
        "mooring_bitt_removed",
        this.handleMooringBittRemoved
      );
    },
    disable() {
      this.mooringBittsManager.clearAll();

      this.mooringBittsManager.off(
        "mooring_bitt_added",
        this.handleMooringBittAdded
      );
      this.mooringBittsManager.off(
        "mooring_bitt_updated",
        this.handleMooringBittUpdated
      );
      this.mooringBittsManager.off(
        "mooring_bitt_removed",
        this.handleMooringBittRemoved
      );
    },
    recreateLayers() {
      if (this.map) {
        if (!this.map.getLayer(LAYERNAME))
          this.map.addLayer(new maptalks.VectorLayer(LAYERNAME));
        else this.map.getLayer(LAYERNAME).clear();
      }
    },

    handleMooringBittAdded(bitt) {
      let drawnBitt = mooringBittUtils.drawMooringBitt(bitt);
      if (drawnBitt) {
        bitt._drawnObject = drawnBitt;
        drawnBitt.addTo(this.map.getLayer(LAYERNAME));
      }
    },
    handleMooringBittRemoved(bitt) {
      if (bitt && bitt._drawnObject) {
        bitt._drawnObject.remove();
      }
    },
    handleMooringBittUpdated(bitt) {
      this.handleMooringBittAdded(bitt);
      this.handleMooringBittRemoved(bitt);
    },
    loadMooringBitts() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("moorings/GET_ALL_MOORING")
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    loadPublicMooringBitts() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("moorings/GET_ALL_PUBLIC_MOORING", this.portLocode)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    processMooringBitts() {
      if (this.mooringBitts) {
        let bitts = this.mooringBitts.map((bitt) => {
          return {
            Id: bitt.id,
            Code: bitt.code,
            Name: bitt.name,
            MapGeom: bitt.map_geom,
            Unavailable: bitt.unavailable,
            Notes: bitt.notes,
            MooringBittGroups: bitt.mooring_bitt_groups,
          };
        });
        this.mooringBittsManager.addMooringBitts(bitts);
        this.$emit("loaded");
      }
    },
  },
};
</script>
