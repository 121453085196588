<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        class="py-5"
        @click="toggled = !toggled"
        :color="toggled ? 'primary' : 'white'"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon :color="toggled ? 'white' : 'primary'">mdi-comment-eye</v-icon>
      </v-btn>
    </template>
    <span> {{ $t("global.observations") }} </span>
  </v-tooltip>
</template>

<script>
export default {
  props: ["show"],
  computed: {
    toggled: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
};
</script>
<style scoped>
.v-btn--active::before {
  opacity: 0;
}
</style>
