import * as maptalks from "maptalks";
/**
 * ZONE DRAW UTILITIES
 */
export default {
  drawZone(zone) {
    var zonePoly = null;
    if (zone && zone.GeoJSON) {
      zonePoly = maptalks.GeoJSON.toGeometry(zone.GeoJSON);
      zonePoly.updateSymbol(this.getZoneStyle());
      zonePoly.setProperties({
        id: zone.id,
        code: zone.Code,
        name: zone.Name,
      });
    }

    return zonePoly;
  },

  drawZones(map, zones) {
    var drawnzones = [];
    if (zones) {
      zones.forEach((zone) => {
        drawnzones.push(this.drawZone(map, zone));
      });
    }
    return drawnzones;
  },

  getZoneStyle() {
    return {
      lineColor: "white",
      lineWidth: 2,
      lineOpacity: window.VUE_APP_DEFAULT_ZONES_OPACITY,
      polygonFill: window.VUE_APP_DEFAULT_ZONES_COLOR,
      polygonOpacity: window.VUE_APP_DEFAULT_ZONES_OPACITY,
      textFaceName: "Nunito",
      textWeight: "normal", //'bold', 'bold'
      textStyle: "normal", //'italic', 'oblique'
      textSize: {
        stops: [
          [15, 0],
          [16, 12],
        ],
      },
      textOpacity: 1,
      textLineSpacing: 0,
      textDx: 0,
      textDy: 25,
      textHorizontalAlignment: "middle", //left | middle | right | auto
      textVerticalAlignment: "middle", // top | middle | bottom | auto
      textAlign: "center", //left | right | center | auto,
      textFill: "whitesmoke",
    };
  },
};
