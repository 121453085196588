<template>
  <div class="map-container">
    <div ref="map" class="map" />

    <div style="position: absolute; top: 20px; left: 20px">
      <v-btn-toggle>
        <ResetView v-if="map && locode" :map="this.map" :portLocode="locode" />
        <Observations v-if="map && observations" :show.sync="show" />
      </v-btn-toggle>
    </div>

    <LayersDrawer v-if="map" :map="this.map" :layers="layers" />

    <Annotations v-if="map" :map="map" :annotations.sync="annotationsList" />

    <VesselCallShips
      :refMap.sync="map"
      :mooringBittsManager.sync="mooringBittsManager"
      :zonesManager="zonesManager"
      v-if="canLoadShips"
      :draggable="false"
      :records.sync="vesselCalls"
      :showBuffer="true"
    />

    <MooringBitts
      :refMap.sync="map"
      v-if="map"
      :mooringBittsManager.sync="mooringBittsManager"
      :portLocode="locode"
      @loaded="hasLoadedMooringBitts = true"
    />

    <Zones
      :refMap.sync="map"
      v-if="map"
      :zonesManager.sync="zonesManager"
      @loaded="hasLoadedZones = true"
      :records.sync="vesselCalls"
      :portLocode="locode"
    />

    <v-snackbar
      v-model="show"
      :timeout="-1"
      :value="true"
      :multi-line="true"
      absolute
      bottom
      color="info"
      outlined
    >
      {{ observations }}
    </v-snackbar>
  </div>
</template>

<script>
import configs from "@/helpers/configs";
import * as maptalks from "maptalks";

import VesselCallShips from "@/components/Map/VesselCallShips";
import MooringBitts from "@/components/Map/MooringBitts";
import MooringBittsManager from "@/helpers/map/mooringbittsmanager";
import Zones from "@/components/Map/Zones";
import ZonesManager from "@/helpers/map/zonesmanager";
import ResetView from "@/components/Public/ResetView";
import Annotations from "@/components/Public/Annotations";
import LayersDrawer from "@/components/Public/LayersDrawer";
import Observations from "@/components/Public/Observations";

const ZONES_LAYERNAME = "zones";
const VCSHIPS_LAYERNAME = "vc_ships";
const MOORINGBITTS_LAYERNAME = "mooring_bitts";
const MOORING_ROPES_LAYERNAME = "mooring_ropes";
export default {
  props: [],
  components: {
    VesselCallShips,
    MooringBitts,
    Zones,
    ResetView,
    Annotations,
    LayersDrawer,
    Observations,
  },
  data() {
    return {
      map: null,
      loading: false,
      locode: null,
      hasLoadedZones: false,
      hasLoadedMooringBitts: false,
      mooringBittsManager: new MooringBittsManager(),
      zonesManager: new ZonesManager(),
      show: false,
    };
  },
  computed: {
    locodes() {
      return configs.getLocodes();
    },
    canLoadShips() {
      return this.map && this.hasLoadedMooringBitts && this.hasLoadedZones;
    },
    annotationsList() {
      return this.$store.state.screenshots.annotations;
    },
    vesselCalls() {
      return this.$store.state.screenshots.vesselCalls;
    },
    layers() {
      return this.$store.state.screenshots.layers;
    },
    observations() {
      return this.$store.state.screenshots.observations;
    },
    portLocode() {
      return this.$store.state.screenshots.portLocode ?? "PTFNC";
    },
    screenshotId() {
      return this.$route.params.id;
    },
    baseLayer() {
      return this.$store.state.screenshots.baseLayer;
    },
    properties() {
      return this.$store.state.screenshots.properties;
    },
  },
  mounted() {
    this.getScreenshotData();
  },
  methods: {
    getScreenshotData() {
      this.$store
        .dispatch("screenshots/GET_SCREENSHOT", this.screenshotId)
        .then((data) => {
          if (!data.error) {
            this.initMap();
          } else {
            this.$router.push({ path: "/snapshot_denied" });
          }
        });
    },
    initMap() {
      let center;
      let bearing;
      let zoom;

      this.locode = this.locodes.find((l) => l.code === this.portLocode);
      if (this.properties) {
        center = this.properties.center;
        bearing = this.properties.bearing;
        zoom = this.properties.zoom;
      } else {
        center = [-8.702487, 41.183199];
        bearing = -38;
        zoom = 15;
      }

      this.map = new maptalks.Map(this.$refs.map, {
        center: center,
        zoom: zoom,
        bearing: bearing,
        seamlessZoom: true,
        maxPitch: 0,
        hitDetect: false, // whether to enable hit detecting of layers for cursor style on this map, disable it to improve performance
        layerCanvasLimitOnInteracting: -1, // -1 to display all layers when interacting
        zoomControl: false, // add zoom control
        scaleControl: false, // add scale control
        seamlessZoom: true, // whether to enable seamless zooming for this map
        attribution: false,
        layerSwitcherControl: false,
        layers: [
          new maptalks.TileLayer(this.baseLayer, {
            urlTemplate: configs.getRasterUrlByCode(this.baseLayer),
            subdomains: ["a", "b", "c", "d"],
          }),
          new maptalks.VectorLayer(MOORINGBITTS_LAYERNAME),
          new maptalks.VectorLayer(MOORING_ROPES_LAYERNAME),
          new maptalks.VectorLayer(ZONES_LAYERNAME),
          new maptalks.VectorLayer(VCSHIPS_LAYERNAME),
        ],
      });
    },
  },
};
</script>

<style scoped>
.map,
.map-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
</style>
<style scoped src="@/../node_modules/maptalks/dist/maptalks.css"></style>
