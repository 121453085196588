import * as maptalks from "maptalks";
import utils from "@/helpers/utils";

/** Predefined constants  */
var SHIP_BOW_LENGTH = 8;
var SHIP_STERN_LENGTH = 5;
var SHIP_STERN_OFFSET = 2;

var SHIP_HAZARDOUSCARGO_OFFSET = 1.25;
var SHIP_ISPS_OFFSET = -1.25;

var SHIP_HAZARDOUSCARGO_WIDTH = 1.25;
var SHIP_ISPS_WIDTH = 1.25;
var SHIP_HAZARDOUSCARGO_HEIGHT = 2.5;
var SHIP_ISPS_HEIGHT = 2.5;

var SHIP_BOW_PERCENT = 0.08; //8%
var SHIP_STERN_PERCENT = 0.03; //3%
var SHIP_STERN_OFFSET_PERCENT = 0.1; //10%
var SHIP_HEADING_ADJUSTMENT = 90;
var SHIP_INVALID_HEADING = 511;

var SHIP_DEFAULT_DIST_FRONT = 20;
var SHIP_DEFAULT_DIST_BACK = 5;
var SHIP_DEFAULT_DIST_LEFT = 4;
var SHIP_DEFAULT_DIST_RIGHT = 4;

var DEFAULT_RADIUS = 5;
var DEFAULT_LINEWITDH = 2;

var DEFAULT_IPSP_ON = "GREEN";
var DEFAULT_IPSP_OFF = "GREY";

var DEFAULT_HAZARDOUSCARGO_ON = "RED";
var DEFAULT_HAZARDOUSCARGO_OFF = "GREY";

export default {
  getShipISPSPoints(shipObjLatLng, distanceToFront, distanceToBack, distanceToLeft, distanceToRight, rotationAngle) {
    var meterCoords = utils.convertCoordsToMeters(shipObjLatLng);
    var shipISPSGeom = [];

    SHIP_BOW_LENGTH = (distanceToFront + distanceToBack) * SHIP_BOW_PERCENT;
    SHIP_STERN_LENGTH = (distanceToFront + distanceToBack) * SHIP_STERN_PERCENT;
    SHIP_STERN_OFFSET = (distanceToLeft + distanceToRight) * SHIP_STERN_OFFSET_PERCENT;

    var latlonAObj = {
      lat: meterCoords.lat + distanceToLeft,
      lon: meterCoords.lon + distanceToFront - SHIP_BOW_LENGTH,
    }; //A
    var latlonBObj = {
      lat: meterCoords.lat - distanceToRight,
      lon: meterCoords.lon + distanceToFront - SHIP_BOW_LENGTH,
    }; //B

    var latlonHObj = {
      lat: (latlonAObj.lat + latlonBObj.lat) / 2 + SHIP_HAZARDOUSCARGO_OFFSET,
      lon: meterCoords.lon - distanceToBack + SHIP_STERN_LENGTH + 2,
    }; //H

    /** H1 ----- H2 */
    /** |         | */
    /** |   H     | */
    /** |         | */
    /** H3 ----- H4 */

    var latLonH1Obj = {
      lat: latlonHObj.lat - SHIP_HAZARDOUSCARGO_WIDTH,
      lon: latlonHObj.lon - SHIP_HAZARDOUSCARGO_HEIGHT,
    }; //H1
    var latLonH2Obj = {
      lat: latlonHObj.lat + SHIP_HAZARDOUSCARGO_WIDTH,
      lon: latlonHObj.lon - SHIP_HAZARDOUSCARGO_HEIGHT,
    }; //H2
    var latLonH3Obj = {
      lat: latlonHObj.lat - SHIP_HAZARDOUSCARGO_WIDTH,
      lon: latlonHObj.lon + SHIP_HAZARDOUSCARGO_HEIGHT,
    }; //H3
    var latLonH4Obj = {
      lat: latlonHObj.lat + SHIP_HAZARDOUSCARGO_WIDTH,
      lon: latlonHObj.lon + SHIP_HAZARDOUSCARGO_HEIGHT,
    }; //H4

    var latlonH1Obj = utils.convertCoordsToWGS84(latLonH1Obj);
    var latlonH2Obj = utils.convertCoordsToWGS84(latLonH2Obj);
    var latlonH3Obj = utils.convertCoordsToWGS84(latLonH3Obj);
    var latlonH4Obj = utils.convertCoordsToWGS84(latLonH4Obj);

    var latlonH1 = [latlonH1Obj.lon, latlonH1Obj.lat];
    var latlonH2 = [latlonH2Obj.lon, latlonH2Obj.lat];
    var latlonH3 = [latlonH3Obj.lon, latlonH3Obj.lat];
    var latlonH4 = [latlonH4Obj.lon, latlonH4Obj.lat];

    latlonH1 = utils.rotatePoint(latlonH1, rotationAngle, shipObjLatLng);
    latlonH2 = utils.rotatePoint(latlonH2, rotationAngle, shipObjLatLng);
    latlonH3 = utils.rotatePoint(latlonH3, rotationAngle, shipObjLatLng);
    latlonH4 = utils.rotatePoint(latlonH4, rotationAngle, shipObjLatLng);

    shipISPSGeom.push(latlonH1);
    shipISPSGeom.push(latlonH2);
    shipISPSGeom.push(latlonH4);
    shipISPSGeom.push(latlonH3);
    return shipISPSGeom;
  },

  getShipISPS(shipObjLatLng, distanceToFront, distanceToBack, distanceToLeft, distanceToRight, rotationAngle, hasISPS) {
    let shipISPSGeom = this.getShipISPSPoints(shipObjLatLng, distanceToFront, distanceToBack, distanceToLeft, distanceToRight, rotationAngle, hasISPS);
    return new maptalks.Polygon([shipISPSGeom], this.getISPSStyle(hasISPS));
  },

  reduceAngle(hdg) {
    //Reducing Angle Measures
    if (hdg < 0) {
      hdg = this.reduceAngle(360 + hdg);
    } else if (hdg >= 360) {
      let division = Math.trunc(hdg / 360);
      let cycles = 360 * division;
      hdg = hdg - cycles;
    }

    return hdg;
  },

  getHeading(hdg) {
    return this.reduceAngle(SHIP_HEADING_ADJUSTMENT + hdg || 0);
  },

  getUncompensatedHeading(hdg) {
    return this.reduceAngle(hdg - SHIP_HEADING_ADJUSTMENT || 0);
  },

  getRadius(dims) {
    var dimA = isNaN(dims.dimA) ? -Infinity : dims.dimA;
    var dimB = isNaN(dims.dimB) ? -Infinity : dims.dimB;
    var dimC = isNaN(dims.dimC) ? -Infinity : dims.dimC;
    var dimD = isNaN(dims.dimD) ? -Infinity : dims.dimD;

    var radius = DEFAULT_RADIUS;
    if (!dims) radius = DEFAULT_RADIUS;
    else radius = Math.max(dimA, dimB, dimC, dimD, DEFAULT_RADIUS);

    return radius;
  },

  getISPSStyle(hasISPS) {
    return {
      symbol: {
        lineColor: "#FFFFFF",
        lineWidth: 1,
        polygonFill: hasISPS ? DEFAULT_IPSP_ON : DEFAULT_IPSP_OFF, //By default all ships have isps, right?
        polygonOpacity: 1,
      },
    };
  },

  getHazardousCargoStyle(hasHazmat) {
    return {
      symbol: {
        lineColor: "#FFFFFF",
        lineWidth: 1,
        polygonFill: hasHazmat ? DEFAULT_HAZARDOUSCARGO_ON : DEFAULT_HAZARDOUSCARGO_OFF,
        polygonOpacity: 1,
      },
    };
  },

  getShipHazardousCargoPoints(shipObjLatLng, distanceToFront, distanceToBack, distanceToLeft, distanceToRight, rotationAngle) {
    var meterCoords = utils.convertCoordsToMeters(shipObjLatLng);
    var shipHazardousCargoGeom = [];

    SHIP_BOW_LENGTH = (distanceToFront + distanceToBack) * SHIP_BOW_PERCENT;
    SHIP_STERN_LENGTH = (distanceToFront + distanceToBack) * SHIP_STERN_PERCENT;
    SHIP_STERN_OFFSET = (distanceToLeft + distanceToRight) * SHIP_STERN_OFFSET_PERCENT;

    var latlonAObj = {
      lat: meterCoords.lat + distanceToLeft,
      lon: meterCoords.lon + distanceToFront - SHIP_BOW_LENGTH,
    }; //A
    var latlonBObj = {
      lat: meterCoords.lat - distanceToRight,
      lon: meterCoords.lon + distanceToFront - SHIP_BOW_LENGTH,
    }; //B

    var latlonIObj = {
      lat: (latlonAObj.lat + latlonBObj.lat) / 2 + SHIP_ISPS_OFFSET,
      lon: meterCoords.lon - distanceToBack + SHIP_STERN_LENGTH + 2,
    }; //H

    /** I1 ----- I2 */
    /** |         | */
    /** |   I     | */
    /** |         | */
    /** I3 ----- I4 */

    var latLonI1Obj = {
      lat: latlonIObj.lat - SHIP_ISPS_WIDTH,
      lon: latlonIObj.lon - SHIP_ISPS_HEIGHT,
    }; //I1
    var latLonI2Obj = {
      lat: latlonIObj.lat + SHIP_ISPS_WIDTH,
      lon: latlonIObj.lon - SHIP_ISPS_HEIGHT,
    }; //I2
    var latLonI3Obj = {
      lat: latlonIObj.lat - SHIP_ISPS_WIDTH,
      lon: latlonIObj.lon + SHIP_ISPS_HEIGHT,
    }; //I3
    var latLonI4Obj = {
      lat: latlonIObj.lat + SHIP_ISPS_WIDTH,
      lon: latlonIObj.lon + SHIP_ISPS_HEIGHT,
    }; //I4

    var latlonI1Obj = utils.convertCoordsToWGS84(latLonI1Obj);
    var latlonI2Obj = utils.convertCoordsToWGS84(latLonI2Obj);
    var latlonI3Obj = utils.convertCoordsToWGS84(latLonI3Obj);
    var latlonI4Obj = utils.convertCoordsToWGS84(latLonI4Obj);

    var latlonI1 = [latlonI1Obj.lon, latlonI1Obj.lat];
    var latlonI2 = [latlonI2Obj.lon, latlonI2Obj.lat];
    var latlonI3 = [latlonI3Obj.lon, latlonI3Obj.lat];
    var latlonI4 = [latlonI4Obj.lon, latlonI4Obj.lat];

    latlonI1 = utils.rotatePoint(latlonI1, rotationAngle, shipObjLatLng);
    latlonI2 = utils.rotatePoint(latlonI2, rotationAngle, shipObjLatLng);
    latlonI3 = utils.rotatePoint(latlonI3, rotationAngle, shipObjLatLng);
    latlonI4 = utils.rotatePoint(latlonI4, rotationAngle, shipObjLatLng);

    shipHazardousCargoGeom.push(latlonI1);
    shipHazardousCargoGeom.push(latlonI2);
    shipHazardousCargoGeom.push(latlonI4);
    shipHazardousCargoGeom.push(latlonI3);
    return shipHazardousCargoGeom;
  },

  getShipHazardousCargo(shipObjLatLng, distanceToFront, distanceToBack, distanceToLeft, distanceToRight, rotationAngle, hazmat) {
    let shipHazardousCargoGeom = this.getShipHazardousCargoPoints(shipObjLatLng, distanceToFront, distanceToBack, distanceToLeft, distanceToRight, rotationAngle);
    return new maptalks.Polygon([shipHazardousCargoGeom], this.getHazardousCargoStyle(hazmat));
  },

  /**
     * 
    distanceToFront = dimA
    distanceToBack = dimB
    distanceToLeft = dimC
    distanceToRight = dimD
     * 
    */
  getShipDimensions(ship) {
    var distanceToFront, distanceToBack, distanceToLeft, distanceToRight;
    if ((!ship.dimA || ship.dimA == 0) && (!ship.dimB || ship.dimB == 0) && (!ship.dimC || ship.dimC == 0) && (!ship.dimD || ship.dimD == 0)) {
      //If all coordenates are 0 or Null, we shape the ship to the default measures
      distanceToFront = SHIP_DEFAULT_DIST_FRONT;
      distanceToBack = SHIP_DEFAULT_DIST_BACK;
      distanceToLeft = SHIP_DEFAULT_DIST_LEFT;
      distanceToRight = SHIP_DEFAULT_DIST_RIGHT;
    } else {
      //If not, keep the original ones
      distanceToFront = ship && ship.dimA ? ship.dimA : 0;
      distanceToBack = ship && ship.dimB ? ship.dimB : 0;
      distanceToLeft = ship && ship.dimC ? ship.dimC : 0;
      distanceToRight = ship && ship.dimD ? ship.dimD : 0;
    }
    return {
      distanceToFront: distanceToFront,
      distanceToBack: distanceToBack,
      distanceToLeft: distanceToLeft,
      distanceToRight: distanceToRight,
    };
  },

  getPoints(ship) {
    var dimensions = this.getShipDimensions(ship);
    return this.getShipPoints(ship.coordinates, dimensions.distanceToFront, dimensions.distanceToBack, dimensions.distanceToLeft, dimensions.distanceToRight, ship.hdg);
  },

  getBufferPoints(ship) {
    var dimensions = this.getShipDimensions(ship);
    return this.getShipBufferPoints(ship.coordinates, dimensions.distanceToFront + ship.bufferA, dimensions.distanceToBack + ship.bufferB, dimensions.distanceToLeft + ship.bufferC, dimensions.distanceToRight + ship.bufferD, ship.hdg);
  },

  getAISShipStyle(shipSpec) {
    let style = {
      smoothness: 0.5,
      symbol: {
        polygonFill: {
          property: "fillColor",
          type: "identity",
        },
        polygonOpacity: 0.2,
        lineColor: {
          property: "lineColor",
          type: "identity",
        },
        lineOpacity: 0.2,
        lineWidth: DEFAULT_LINEWITDH,
        textName: "{name}",
        textFaceName: "Nunito",
        textHaloFill: "#fff",
        textHaloRadius: 1,
        textSize: {
          stops: [
            [16, 0],
            [17, 8],
            [18, 10],
          ],
        },
        textWeight: "900",
        textOpacity: 1,
        textRotation: 0,
        textDx: 0,
        textDy: 0,
      },
    };

    //updates the rotation of a symbol based on ship heading
    if (shipSpec && shipSpec.hdg && shipSpec.hdg != SHIP_INVALID_HEADING) {
      let hdgAux = (shipSpec.hdg + 90) % 360;

      if (hdgAux > 180) style["symbol"]["textRotation"] = -shipSpec.hdg + 180;
      else style["symbol"]["textRotation"] = -shipSpec.hdg;
    }

    return style;
  },

  getVCShipStyle(shipSpec) {
    let style = {
      smoothness: 0.5,
      symbol: {
        polygonFill: {
          property: "fillColor",
          type: "identity",
        },
        lineWidth: 2,
        lineOpacity: 1,
        lineColor: {
          property: "color",
          type: "identity",
        },
        polygonOpacity: 1,
        lineDasharray: {
          property: "lineDasharray",
          type: "identity",
        },
        textName: "{name} [{length}x{width}]\n{dateStr}",
        textFaceName: "Nunito",
        textHaloFill: "#fff",
        textHaloRadius: 1,
        textSize: {
          stops: [
            [16, 0],
            [17, 8],
            [18, 10],
          ],
        },
        textWeight: "900",
        textOpacity: 1,
        //textPlacement: "line",
        textRotation: 0,
        textRotationAlignment: "viewport",
        textDx: 0,
        textDy: 0,
      },
    };

    //updates the rotation of a symbol based on ship heading
    if (shipSpec && shipSpec.hdg) {
      let hdgAux = (shipSpec.hdg + 90) % 360;

      if (hdgAux > 180) style["symbol"]["textRotation"] = -shipSpec.hdg + 180;
      else style["symbol"]["textRotation"] = -shipSpec.hdg;
    }

    //change style if ship is temporary
    if (shipSpec && shipSpec.temporary) {
      style["symbol"]["polygonPatternFile"] = "img/temporary.png";
    }

    //change label ship if _showCoords
    if (shipSpec && shipSpec._showCoords && (shipSpec.sternMooringBitt == null || shipSpec.sternMooringBitt.Code == null || shipSpec.bowMooringBitt == null || shipSpec.bowMooringBitt.Code == null)) {
      style["symbol"]["textName"] = "{name}\nLat: {lat}\nLon: {lon}\nHDG: {hdg}º";
      style["symbol"]["textFill"] = "black";
      style["symbol"]["textHaloFill"] = "#fff";
      style["symbol"]["textHaloRadius"] = 2;
      style["symbol"]["textSize"] = 12;
      style["symbol"]["textWeight"] = "bold";
    }
    return style;
  },

  getShipPoints(shipObjLatLng, distanceToFront, distanceToBack, distanceToLeft, distanceToRight, rotationAngle) {
    var shipSkelLatlons = [];
    var meterCoords = utils.convertCoordsToMeters(shipObjLatLng);
    /****
     * A------B
     * |      |
     * |      |
     * |      |
     * |      |
     * | P    |
     * |      |
     * D------C
     *
     * P = AIS Coords
     *
     * A => LAT = (P.LAT + DISTANCELEFT) & LON = (P.LON + DISTANCEFRONT)
     * B => LAT = (P.LAT - DISTANCERIGHT) & LON = (P.LON + DISTANCEFRONT)
     * C => LAT = (P.LAT - DISTANCERIGHT) & LON = (P.LON - DISTANCEBACK)
     * D => LAT = (P.LAT + DISTANCELEFT) & LON = (P.LON - DISTANCEBACK)
     *
     * However, we want to shape a "real" ship:
     *
     *    E
     *    /\
     *   /  \
     *  /    \
     * A------B
     * |      |
     * |      |
     * |      |
     * |      |
     * | P    |
     * |  IH  |
     * D\----/C
     *  F--Z--G
     *
     *
     *
     */

    SHIP_BOW_LENGTH = (distanceToFront + distanceToBack) * SHIP_BOW_PERCENT;

    SHIP_STERN_LENGTH = (distanceToFront + distanceToBack) * SHIP_STERN_PERCENT;

    SHIP_STERN_OFFSET = (distanceToLeft + distanceToRight) * SHIP_STERN_OFFSET_PERCENT;

    var latlonAObj = {
      lat: meterCoords.lat + distanceToLeft,
      lon: meterCoords.lon + distanceToFront - SHIP_BOW_LENGTH,
    }; //A
    var latlonBObj = {
      lat: meterCoords.lat - distanceToRight,
      lon: meterCoords.lon + distanceToFront - SHIP_BOW_LENGTH,
    }; //B
    var latlonDObj = {
      lat: meterCoords.lat + distanceToLeft,
      lon: meterCoords.lon - distanceToBack + SHIP_STERN_LENGTH,
    }; //D
    var latlonCObj = {
      lat: meterCoords.lat - distanceToRight,
      lon: meterCoords.lon - distanceToBack + SHIP_STERN_LENGTH,
    }; //C
    var latlonEObj = {
      lat: (latlonAObj.lat + latlonBObj.lat) / 2,
      lon: latlonAObj.lon + SHIP_BOW_LENGTH,
    }; //E
    var latlonFObj = {
      lat: meterCoords.lat + distanceToLeft - SHIP_STERN_OFFSET,
      lon: meterCoords.lon - distanceToBack,
    }; //F
    var latlonGObj = {
      lat: meterCoords.lat - distanceToRight + SHIP_STERN_OFFSET,
      lon: meterCoords.lon - distanceToBack,
    }; //G
    var latlonZObj = {
      lat: (latlonAObj.lat + latlonBObj.lat) / 2,
      lon: meterCoords.lon - distanceToBack,
    }; //Z

    latlonAObj = utils.convertCoordsToWGS84(latlonAObj);
    latlonBObj = utils.convertCoordsToWGS84(latlonBObj);
    latlonDObj = utils.convertCoordsToWGS84(latlonDObj);
    latlonCObj = utils.convertCoordsToWGS84(latlonCObj);
    latlonEObj = utils.convertCoordsToWGS84(latlonEObj);
    latlonFObj = utils.convertCoordsToWGS84(latlonFObj);
    latlonGObj = utils.convertCoordsToWGS84(latlonGObj);
    latlonZObj = utils.convertCoordsToWGS84(latlonZObj);

    var latlonA = [latlonAObj.lon, latlonAObj.lat]; //A
    var latlonB = [latlonBObj.lon, latlonBObj.lat]; //B
    var latlonD = [latlonDObj.lon, latlonDObj.lat]; //D
    var latlonC = [latlonCObj.lon, latlonCObj.lat]; //C
    var latlonE = [latlonEObj.lon, latlonEObj.lat]; //E
    var latlonF = [latlonFObj.lon, latlonFObj.lat]; //F
    var latlonG = [latlonGObj.lon, latlonGObj.lat]; //G
    var latlonZ = [latlonZObj.lon, latlonZObj.lat]; //Z

    latlonA = utils.rotatePoint(latlonA, rotationAngle, shipObjLatLng);
    latlonB = utils.rotatePoint(latlonB, rotationAngle, shipObjLatLng);
    latlonD = utils.rotatePoint(latlonD, rotationAngle, shipObjLatLng);
    latlonC = utils.rotatePoint(latlonC, rotationAngle, shipObjLatLng);
    latlonE = utils.rotatePoint(latlonE, rotationAngle, shipObjLatLng);
    latlonF = utils.rotatePoint(latlonF, rotationAngle, shipObjLatLng);
    latlonG = utils.rotatePoint(latlonG, rotationAngle, shipObjLatLng);
    latlonZ = utils.rotatePoint(latlonZ, rotationAngle, shipObjLatLng);

    shipSkelLatlons.push(latlonA);
    //colocar mais pontos intermedios
    shipSkelLatlons.push(latlonE);
    //colocar mais pontos intermedios
    shipSkelLatlons.push(latlonB);
    shipSkelLatlons.push(latlonC);
    //colocar mais pontos intermedios
    shipSkelLatlons.push(latlonG);
    //colocar mais pontos intermedios
    shipSkelLatlons.push(latlonZ);
    //colocar mais pontos intermedios
    shipSkelLatlons.push(latlonF);
    //colocar mais pontos intermedios
    shipSkelLatlons.push(latlonD);
    return shipSkelLatlons;
  },

  getShipBufferPoints(shipObjLatLng, distanceToFront, distanceToBack, distanceToLeft, distanceToRight, rotationAngle) {
    var shipSkelLatlons = [];
    var meterCoords = utils.convertCoordsToMeters(shipObjLatLng);
    /****
     * A------B
     * |      |
     * |      |
     * |      |
     * |      |
     * | P    |
     * |      |
     * D------C
     *
     * P = Centroid Coords
     *
     * A => LAT = (P.LAT + DISTANCELEFT) & LON = (P.LON + DISTANCEFRONT)
     * B => LAT = (P.LAT - DISTANCERIGHT) & LON = (P.LON + DISTANCEFRONT)
     * C => LAT = (P.LAT - DISTANCERIGHT) & LON = (P.LON - DISTANCEBACK)
     * D => LAT = (P.LAT + DISTANCELEFT) & LON = (P.LON - DISTANCEBACK)
     *
     */

    SHIP_BOW_LENGTH = distanceToFront + distanceToBack;

    SHIP_STERN_LENGTH = distanceToFront + distanceToBack;

    SHIP_STERN_OFFSET = distanceToLeft + distanceToRight;

    var latlonAObj = {
      lat: meterCoords.lat + distanceToLeft,
      lon: meterCoords.lon + distanceToFront - SHIP_BOW_LENGTH,
    }; //A
    var latlonBObj = {
      lat: meterCoords.lat - distanceToRight,
      lon: meterCoords.lon + distanceToFront - SHIP_BOW_LENGTH,
    }; //B
    var latlonDObj = {
      lat: meterCoords.lat + distanceToLeft,
      lon: meterCoords.lon - distanceToBack + SHIP_STERN_LENGTH,
    }; //D
    var latlonCObj = {
      lat: meterCoords.lat - distanceToRight,
      lon: meterCoords.lon - distanceToBack + SHIP_STERN_LENGTH,
    }; //C

    latlonAObj = utils.convertCoordsToWGS84(latlonAObj);
    latlonBObj = utils.convertCoordsToWGS84(latlonBObj);
    latlonDObj = utils.convertCoordsToWGS84(latlonDObj);
    latlonCObj = utils.convertCoordsToWGS84(latlonCObj);

    var latlonA = [latlonAObj.lon, latlonAObj.lat]; //A
    var latlonB = [latlonBObj.lon, latlonBObj.lat]; //B
    var latlonD = [latlonDObj.lon, latlonDObj.lat]; //D
    var latlonC = [latlonCObj.lon, latlonCObj.lat]; //C

    latlonA = utils.rotatePoint(latlonA, rotationAngle, shipObjLatLng);
    latlonB = utils.rotatePoint(latlonB, rotationAngle, shipObjLatLng);
    latlonD = utils.rotatePoint(latlonD, rotationAngle, shipObjLatLng);
    latlonC = utils.rotatePoint(latlonC, rotationAngle, shipObjLatLng);

    shipSkelLatlons.push(latlonA);
    shipSkelLatlons.push(latlonB);
    shipSkelLatlons.push(latlonC);
    shipSkelLatlons.push(latlonD);
    return shipSkelLatlons;
  },

  hasInvalidHeading(hdg) {
    return (!hdg && hdg != 0) || hdg == SHIP_INVALID_HEADING;
  },

  /**
   * A circle is when a ship does not have a valid hdg, therefor it can be rotated
   * anywhere within the radius given by the circle
   *
   */
  getCircle(ship, style) {
    ship.geomType = "circle";
    var geom = new maptalks.Circle(ship.coordinates, ship.radius, style ? style : this.getVCShipStyle(ship));
    return geom;
  },

  getPolygon(ship, style) {
    ship.geomType = "polygon";
    let points = this.getPoints(ship);
    var geom = new maptalks.Polygon(points, style ? style : this.getVCShipStyle(ship));
    return geom;
  },

  getShipSkeleton(ship, style) {
    var geom;
    if (ship.hasInvalidHeading || this.hasInvalidHeading(ship.hdg)) geom = this.getCircle(ship, style);
    else geom = this.getPolygon(ship, style);
    return geom;
  },

  getShipBuffer(ship) {
    let points = this.getBufferPoints(ship);
    var geom = new maptalks.Polygon(points, {
      symbol: {
        lineColor: "yellow",
        lineWidth: 0,
        polygonPatternFile: "img/stripes.png",
        polygonOpacity: ship.bufferA + ship.bufferB + ship.bufferC + ship.bufferD > 0 ? 0.2 : 0,
      },
    });
    return geom;
  },

  getShipAntennaStyle() {
    return {
      markerType: "ellipse",
      markerWidth: {
        stops: [
          [15, 0],
          [16, 5],
        ],
      },
      markerHeight: {
        stops: [
          [15, 0],
          [16, 5],
        ],
      },
      markerFill: "#FFFFFF",
      markerFillOpacity: 0,
      markerLineColor: "#000000",
      markerLineWidth: 0.5,
    };
  },

  getShipBittStyle() {
    return {
      markerType: "ellipse",
      markerWidth: {
        stops: [
          [15, 0],
          [16, 5],
        ],
      },
      markerHeight: {
        stops: [
          [15, 0],
          [16, 5],
        ],
      },
      markerFill: "#000000",
      markerFillOpacity: 1,
      markerLineColor: "#000000",
      markerLineWidth: 1,
    };
  },
};
