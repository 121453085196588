import * as maptalks from "maptalks";
import * as turf from "@turf/turf";
import * as wkt from "wkt";
import shipUtils from "@/helpers/ships/ship_utils";
import mooringbitt_utils from "@/helpers/map/mooringbitt_utils";
const MOORING_ROPES_LAYERNAME = "mooring_ropes";

const MOORINGBITTBOW_POS = 1;
const MOORINGBITTSTERN_POS = 5;
const WIDTH_MULTIPLICATION_FACTOR = 1.2; //Width/2 * WIDTH_MULTIPLICATION_FACTOR , so this is basically /2 times the width of the ship
const HIGHLIGHT_LINE_WIDTH = 4;
const HIGHLIGHT_LINE_COLOR = "yellow";
const MOORING_BITT_IN_USE_COLOR = "#1976d2";

const MOORING_BITT_MIN_DISTANCE_PERCENTAGE = 20;

const DEFAULT_SHIP_LENGTH = 20;
const DEFAULT_SHIP_WIDTH = 5;

/**
 * Ship abstraction that contains several parts:
 *
 * *
 *    E
 *    /\
 *   /  \
 *  /    \
 * A------B
 * |      |
 * |      |
 * |      |
 * |      |
 * | ___  |
 * ||1|2| |
 * ||_|_| |
 * D\----/C
 *  F--Z--G
 *
 *
 *
 * - Hull (main ship part)
 * - ISPS Code (1)
 * - Dangerous Cargo (2)
 *
 */
export default class Ship extends maptalks.GeometryCollection {
  /**
   * @param {Geometry[]} geometries - GeometryCollection's geometries
   * @param {Object} [opts=null] - options defined in [nGeometryCollection]{@link GeometryCollection#options}
   * @param {Object} [specification=null] - set of specifications used to represent this particular ship
   */
  constructor(
    geometries,
    opts,
    specification,
    mooringBittsManager,
    zonesManager
  ) {
    super(geometries, opts);

    this.on("click", (e) => {
      if (e && e.domEvent) {
        e.domEvent.preventDefault();
        e.domEvent.stopPropagation();
      }
      this.fire("ship_selected", {
        id: this.properties.id,
      });
    });

    this.mooringBittsManager = mooringBittsManager;
    this.zonesManager = zonesManager;

    this.bowShipBitt = null;
    this.sternShipBitt = null;

    this.bowConnector = null;
    this.sternConnector = null;

    this.highlighted = false;

    // The increment on what ships are represented. Used to prevent overlapping
    this._bumpedDistance = 0;

    this.setShipSpec(specification);
    this._createShape();
  }

  resetBump() {
    this._rearranged = false;
    this._bumpedDistance = 0;
    this._updateShape();
    this.updateBuffer();
  }

  bumpOrder(distance) {
    if (distance) {
      try {
        distance = parseFloat(distance);
      } catch (e) {
        distance = 0;
      }
      this._bumpedDistance += distance;
    }
    this._updateShape();
    this.updateBuffer();
  }

  getShipCenterOrigin() {
    if (this.shipSpecification && this.shipSpecification.coordinates) {
      return {
        x: this.shipSpecification.coordinates[0],
        y: this.shipSpecification.coordinates[1],
      };
    } else {
      return super.getCenter();
    }
  }

  getWKTPosition() {
    let coords = this.getPosition();
    return "POINT(" + coords.x + " " + coords.y + ")";
  }

  getPosition() {
    if (this.antenna) {
      return this.antenna.getCoordinates();
    }
    return {
      x: null,
      y: null,
    };
  }

  setShipAntenna(antenna) {
    this.antenna = antenna;
  }

  getBowShipBitt() {
    return this.bowShipBitt;
  }

  setBowShipBitt(bitt) {
    this.bowShipBitt = bitt;
  }

  getSternShipBitt() {
    return this.sternShipBitt;
  }

  setSternShipBitt(bitt) {
    this.sternShipBitt = bitt;
  }

  getBowConnector() {
    return this.bowConnector;
  }

  getSternConnector() {
    return this.sternConnector;
  }

  clearBowConnector() {
    if (this.bowConnector) {
      let oldBitt = this.bowConnector.getConnectTarget();
      if (oldBitt) {
        oldBitt.updateSymbol(mooringbitt_utils.getMooringBittStyle());
      }
      this.bowConnector.remove();
      this.bowConnector = null;
    }
  }

  clearSternConnector() {
    if (this.sternConnector) {
      let oldBitt = this.sternConnector.getConnectTarget();
      if (oldBitt) {
        oldBitt.updateSymbol(mooringbitt_utils.getMooringBittStyle());
      }
      this.sternConnector.remove();
      this.sternConnector = null;
    }
  }

  setBowConnector(connector) {
    this.bowConnector = connector;
    this._changeBowTarget();
  }

  setSternConnector(connector) {
    this.sternConnector = connector;
    this._changeSternTarget();
  }

  setHull(hull) {
    this.hull = hull;
  }

  setBuffer(buffer) {
    this.buffer = buffer;
  }

  setISPS(isps) {
    this.isps = isps;
  }

  setHazmat(hazmat) {
    this.hazmat = hazmat;
  }

  updateShipSpec(spec) {
    this.setShipSpec(spec);
    this._updateShape();
    this.updateBuffer();
  }

  restoreShipSpec(specToParse) {
    let newSpec = JSON.parse(JSON.stringify(this.originalSpecification));
    if (specToParse) {
      if (specToParse.coordinates) {
        newSpec.MapGeom =
          "POINT(" +
          specToParse.coordinates[0] +
          " " +
          specToParse.coordinates[1] +
          ")";
      } else {
        newSpec.MapGeom = null;
      }
      if (specToParse.berth_location) {
        newSpec.BerthLocation = specToParse.berth_location;
      }

      if (
        typeof specToParse.hdg === "number" &&
        !Number.isNaN(specToParse.hdg)
      ) {
        newSpec.Heading = specToParse.hdg;
      }

      newSpec.AbsolutePositioning = specToParse._absolutePositioning;

      if (specToParse.bowMooringBitt && specToParse.bowMooringBitt.Code) {
        newSpec.BowMooringBitt = {
          Code: specToParse.bowMooringBitt.Code,
        };
      } else {
        newSpec.BowMooringBitt = {
          Code: null,
        };
      }
      if (specToParse.sternMooringBitt && specToParse.sternMooringBitt.Code) {
        newSpec.SternMooringBitt = {
          Code: specToParse.sternMooringBitt.Code,
        };
      } else {
        newSpec.SternMooringBitt = {
          Code: null,
        };
      }
    }
    return newSpec;
  }

  /**
   *
   * @param spec - the spec param here is the result after calling setShipSpec() function
   */
  restoreFromShipSpec(spec) {
    let originalSpec = this.restoreShipSpec(spec);
    if (!originalSpec.AbsolutePositioning) {
      // by mooring bitts or simply dragged out of reach of mooring bitts, or a zone
      if (
        originalSpec.BowMooringBitt &&
        originalSpec.BowMooringBitt.Code &&
        originalSpec.SternMooringBitt &&
        originalSpec.SternMooringBitt.Code
      ) {
        originalSpec.MapGeom = null;
        originalSpec.Heading = null;
        this.clearBowConnector();
        this.clearSternConnector();
      }
    }
    this.updateShipSpec(originalSpec);
    this._updateConnectors(
      false /* do not suggest automatically mooring bitts */
    );
    this.fire("position_updated", {
      target: this,
    });
  }

  _highlightHoveringZones(coords) {
    if (coords && this.zonesManager) {
      this.zonesManager.resetStyle();
      for (let entry of this.zonesManager.getZones()) {
        let zone = entry[1];
        if (zone && zone._drawnObject) {
          if (
            zone._drawnObject.containsPoint(
              new maptalks.Coordinate(coords[0], coords[1])
            )
          ) {
            zone._drawnObject.updateSymbol({
              polygonOpacity: 0.6,
            });
          }
        }
      }
    }
  }

  _getClosestMooringBitts(originShipBitt, maximumShipToBittsDistance) {
    let closestMooringBitts = [];
    if (originShipBitt && this.mooringBittsManager) {
      var map = this.getMap();
      let mooringBittCandidates = this.mooringBittsManager.getMooringBitts();
      let candidates = Array.from(mooringBittCandidates.values()).filter(
        (candidate) => !!candidate._drawnObject
      );
      const p1 = originShipBitt.getCoordinates();
      for (let j = 0, length = candidates.length; j < length; j++) {
        const target = candidates[j];
        const p2 = target._drawnObject.getCoordinates();
        const dist = map.computeLength(p1, p2);
        closestMooringBitts.push({
          bitt: target,
          distance: dist,
        });
      }
    }

    closestMooringBitts = closestMooringBitts
      .filter((b) => b.distance <= maximumShipToBittsDistance)
      .sort((a, b) => {
        return a.distance - b.distance;
      });
    return closestMooringBitts;
  }

  _changeBowTarget(target) {
    if (!this.bowConnector && target) {
      let rope = mooringbitt_utils.drawBowMooringRope(this, target);
      this.bowConnector = rope;
      rope.addTo(this.getMap().getLayer(MOORING_ROPES_LAYERNAME));
    }

    if (this.bowConnector) {
      let oldBitt = this.bowConnector.getConnectTarget();
      if (oldBitt) {
        oldBitt.updateSymbol(mooringbitt_utils.getMooringBittStyle());
      }
      if (target) {
        try {
          this.bowConnector.setConnectTarget(target);
        } catch (error) {
          console.warn(error);
        }
      }
      if (oldBitt) {
        let bC = this.bowConnector.getConnectTarget();
        if (!bC.properties.unavailable) {
          bC.updateSymbol({
            markerFill: MOORING_BITT_IN_USE_COLOR,
          });
        }
      }
    }
  }

  _changeSternTarget(target) {
    if (!this.sternConnector && target) {
      let rope = mooringbitt_utils.drawSternMooringRope(this, target);
      this.sternConnector = rope;
      rope.addTo(this.getMap().getLayer(MOORING_ROPES_LAYERNAME));
    }

    if (this.sternConnector) {
      let oldBitt = this.sternConnector.getConnectTarget();
      if (oldBitt) {
        oldBitt.updateSymbol(mooringbitt_utils.getMooringBittStyle());
      }
      if (target) {
        try {
          this.sternConnector.setConnectTarget(target);
        } catch (error) {
          console.warn(error);
        }
      }
      if (oldBitt) {
        let bC = this.sternConnector.getConnectTarget();
        if (!bC.properties.unavailable) {
          bC.updateSymbol({
            markerFill: MOORING_BITT_IN_USE_COLOR,
          });
        }
      }
    }
  }

  _suggestZone(coords) {
    if (this.zonesManager) {
      this._highlightHoveringZones(coords);
    }
  }

  _suggestConnectors(bowShipBitt, sternShipBitt) {
    this.mooringBittsManager.resetStyle();

    if (!this.shipSpecification._absolutePositioning) {
      let possibleMooringBitts = this._getPossibleMooringBitts(
        bowShipBitt,
        sternShipBitt
      );
      if (possibleMooringBitts && this.mooringBittsManager) {
        let closestBittForBow = possibleMooringBitts.bow.bitt;
        let closestBittForStern = possibleMooringBitts.stern.bitt;
        let highlightBittStyle = {
          markerLineColor: "red",
          markerLineWidth: 2,
        };
        closestBittForBow._drawnObject.updateSymbol(highlightBittStyle);
        closestBittForStern._drawnObject.updateSymbol(highlightBittStyle);
      }
    }
  }

  /**
   * Returns a list of options to dock the ship
   * @param {
   * } coords - Optional, send only when you want to force a location
   */
  _getPossibleMooringBitts(bowShipBitt, sternShipBitt) {
    let map = this.getMap();
    let shipLength = this.shipSpecification.length || this.getLength();

    let minBittDistance =
      shipLength * (1 + MOORING_BITT_MIN_DISTANCE_PERCENTAGE / 100);

    let closestBittsForBow = [],
      closestBittsForStern = [];
    if (bowShipBitt && sternShipBitt) {
      closestBittsForBow = this._getClosestMooringBitts(
        bowShipBitt,
        shipLength
      );
      closestBittsForStern = this._getClosestMooringBitts(
        sternShipBitt,
        shipLength
      );
    } else {
      closestBittsForBow = this._getClosestMooringBitts(
        this.getBowShipBitt(),
        shipLength
      );
      closestBittsForStern = this._getClosestMooringBitts(
        this.getSternShipBitt(),
        shipLength
      );
    }

    //Now, check the distance between each mooring bitt to the next:
    let bittPairs = [];
    closestBittsForBow.forEach((bowBitt) => {
      closestBittsForStern.forEach((sternBitt) => {
        // Evaluate the bitts distance:
        let distBetweenBitts = map.computeLength(
          bowBitt.bitt._drawnObject.getCoordinates(),
          sternBitt.bitt._drawnObject.getCoordinates()
        );
        if (distBetweenBitts >= minBittDistance) {
          // This is a possible bitt match
          bittPairs.push({
            bow: bowBitt,
            stern: sternBitt,
            distance: distBetweenBitts,
          });
        }
      });
    });

    //Sort by the shortest distance
    bittPairs = bittPairs.sort((a, b) => {
      return (
        a.bow.distance - b.bow.distance ||
        a.stern.distance - b.stern.distance ||
        a.distance - b.distance
      );
    });
    return bittPairs.shift();
  }

  _updateConnectors(suggestMooringBitts = true) {
    let closestBittForBow, closestBittForStern;
    if (this.shipSpecification._absolutePositioning) {
      if (this.antenna) {
        let newAntennaCoords = this.antenna.getCoordinates();
        this.shipSpecification.coordinates = [
          newAntennaCoords.x,
          newAntennaCoords.y,
        ];
      }
      if (
        this.shipSpecification.bowMooringBitt &&
        this.shipSpecification.bowMooringBitt.Code &&
        this.shipSpecification.sternMooringBitt &&
        this.shipSpecification.sternMooringBitt.Code &&
        this.mooringBittsManager
      ) {
        closestBittForBow = this.mooringBittsManager.getMooringBittByCode(
          this.shipSpecification.bowMooringBitt.Code
        );
        closestBittForStern = this.mooringBittsManager.getMooringBittByCode(
          this.shipSpecification.sternMooringBitt.Code
        );
      }

      // Set the bow/stern target if needed to apply accordingly to the shipSpecification
      if (closestBittForBow && closestBittForStern) {
        this._changeBowTarget(closestBittForBow._drawnObject);
        this._changeSternTarget(closestBittForStern._drawnObject);
        //set mooring bitts
        this.shipSpecification.bowMooringBitt = closestBittForBow;
        this.shipSpecification.sternMooringBitt = closestBittForStern;
      }
      if (this.bowConnector) {
        this._changeBowTarget(this.bowConnector.getConnectTarget());
      }
      if (this.sternConnector) {
        this._changeSternTarget(this.sternConnector.getConnectTarget());
      }
    } else {
      if (
        !suggestMooringBitts &&
        this.shipSpecification.bowMooringBitt &&
        this.shipSpecification.bowMooringBitt.Code &&
        this.shipSpecification.sternMooringBitt &&
        this.shipSpecification.sternMooringBitt.Code
      ) {
        if (this.mooringBittsManager) {
          closestBittForBow = this.mooringBittsManager.getMooringBittByCode(
            this.shipSpecification.bowMooringBitt.Code
          );
          closestBittForStern = this.mooringBittsManager.getMooringBittByCode(
            this.shipSpecification.sternMooringBitt.Code
          );
        }
      } else {
        let possibleMooringBitts = this._getPossibleMooringBitts();
        if (possibleMooringBitts) {
          closestBittForBow = possibleMooringBitts.bow.bitt;
          closestBittForStern = possibleMooringBitts.stern.bitt;
        }
      }
      // Evaluate if the ship is sufficiently near both mooring bitts in a straight distance.
      // If not, update only its center location
      if (closestBittForBow && closestBittForStern) {
        this._changeBowTarget(closestBittForBow._drawnObject);
        this._changeSternTarget(closestBittForStern._drawnObject);

        //set mooring bitts
        this.shipSpecification.bowMooringBitt = closestBittForBow;
        this.shipSpecification.sternMooringBitt = closestBittForStern;
      } else {
        if (this.antenna) {
          let newAntennaCoords = this.antenna.getCoordinates();
          this.shipSpecification.coordinates = [
            newAntennaCoords.x,
            newAntennaCoords.y,
          ];

          //clear mooring bitts
          this.shipSpecification.bowMooringBitt = null;
          this.shipSpecification.sternMooringBitt = null;
          this.clearBowConnector();
          this.clearSternConnector();
        }
      }
    }
    this._updateShape();
    this.updateBuffer();
    this.fire("position_updated", {
      target: this,
    });
  }

  getLength() {
    if (this.shipSpecification) {
      return this.shipSpecification.dimB * 2;
    }
  }

  setShipSpec(spec) {
    if (!spec.Length) {
      spec.Length = DEFAULT_SHIP_LENGTH;
    }
    if (!spec.Width) {
      spec.Width = DEFAULT_SHIP_WIDTH;
    }

    if (!spec.shipDimensions)
      spec.shipDimensions = {
        dimA: spec.Length / 2,
        dimB: spec.Length / 2,
        dimC: spec.Width / 2,
        dimD: spec.Width / 2,
      };

    var specification = {
      id: spec.Id,
      status: spec.Status,
      temporary: spec.Temporary,
      name: spec.Name,
      radius: shipUtils.getRadius(spec.shipDimensions),
      berth_location: spec.BerthLocation,
      draggable: spec.Draggable,
      isps: spec.ISPS,
      hazmat: spec.Hazmat,
      imo: spec.IMO,
      process_number: spec.ProcessNumber,
      mmsi: spec.MMSI,
      observations: spec.Observations,
      length: spec.Length,
      lat: spec.Lat ? parseFloat(spec.Lat).toFixed(8) : null,
      lon: spec.Lon ? parseFloat(spec.Lon).toFixed(8) : null,
      hdg: parseFloat(spec.Heading),
      width: spec.Width,
      datestr: spec.Date,
      color: spec.color,
      flag: spec.Flag,
      bufferA: spec.BufferBow,
      bufferB: spec.BufferStern,
      bufferC: spec.BufferPortside,
      bufferD: spec.BufferStarboard,
      typeName: spec.TypeName,
      invertVector: spec.InvertVector,
      _showCoords: spec.ShowCoords,
      _showBuffer: spec.ShowBuffer,
      startboard: spec.Startboard,
      ...spec.shipDimensions,
    };

    if (spec.MapGeom) {
      let location = wkt.parse(spec.MapGeom);
      specification.coordinates = location.coordinates;
    } else {
      //or, if exists, the position should be self determinated by mooring bitts
      if (
        spec.BowMooringBitt &&
        spec.SternMooringBitt &&
        spec.BowMooringBitt.Code &&
        spec.SternMooringBitt.Code
      ) {
        if (this.mooringBittsManager) {
          let bowMooringBittSpec =
            this.mooringBittsManager.getMooringBittByCode(
              spec.BowMooringBitt.Code
            );
          let sternMooringBittSpec =
            this.mooringBittsManager.getMooringBittByCode(
              spec.SternMooringBitt.Code
            );

          if (bowMooringBittSpec && sternMooringBittSpec) {
            specification.coordinates = this._getMooringBittsMidpoint(
              spec.BowMooringBitt.Code,
              spec.SternMooringBitt.Code,
              spec.Width / 2
            );
            specification.bowMooringBitt = spec.BowMooringBitt;
            specification.sternMooringBitt = spec.SternMooringBitt;
          }
        }
      } else if (spec.BerthLocation) {
        specification.coordinates = this._getZoneMidpoint(spec.BerthLocation);
      } else {
        specification.coordinates = [spec.Lat, spec.Lon];
      }
    }
    //save this, even if we are not positioning by mooring bitts
    if (spec.BowMooringBitt && spec.SternMooringBitt) {
      specification.bowMooringBitt = spec.BowMooringBitt;
      specification.sternMooringBitt = spec.SternMooringBitt;
    }

    if (typeof spec.Heading === "number" && !Number.isNaN(spec.Heading)) {
      specification.hdg = spec.Heading;

      specification.hasInvalidHeading = shipUtils.hasInvalidHeading(
        spec.Heading
      );
    }

    // hdg should be calculated through mooring bitts
    if (
      spec.BowMooringBitt &&
      spec.BowMooringBitt.Code &&
      spec.SternMooringBitt &&
      spec.SternMooringBitt.Code
    ) {
      let hdg = this._getHeadingFromMooringBitts(
        spec.BowMooringBitt.Code,
        spec.SternMooringBitt.Code
      );
      specification.hdg = hdg;
      specification.hasInvalidHeading = shipUtils.hasInvalidHeading(hdg);
    }

    specification._absolutePositioning = spec.AbsolutePositioning
      ? spec.AbsolutePositioning
      : false;
    this.shipSpecification = specification;
    this.originalSpecification = spec;
  }

  /*
     Both arguments are nullable, only read if bow/stern connectors are not created
    */
  _getHeadingFromMooringBitts(bowMooringBittCode, sternMooringBittCode) {
    var bowMooringBitt, sternMooringBitt, bowBittCoords, sternBittCoords;
    //if this is true, we want to get a hdg for these specific mooring bitts
    if (
      bowMooringBittCode &&
      sternMooringBittCode &&
      this.mooringBittsManager
    ) {
      //get hdg from spec, we haven't got the ship drawn yet
      let bowMooringBittSpec =
        this.mooringBittsManager.getMooringBittByCode(bowMooringBittCode);
      let sternMooringBittSpec =
        this.mooringBittsManager.getMooringBittByCode(sternMooringBittCode);
      if (!bowMooringBittSpec || !sternMooringBittSpec) {
        return 0;
      }

      let bowLocation = wkt.parse(bowMooringBittSpec.MapGeom);
      let sternLocation = wkt.parse(sternMooringBittSpec.MapGeom);
      let bowCoords = bowLocation.coordinates;
      bowBittCoords = {
        x: bowCoords[0],
        y: bowCoords[1],
      };

      let sternCoords = sternLocation.coordinates;
      sternBittCoords = {
        x: sternCoords[0],
        y: sternCoords[1],
      };
    } else {
      if (this.bowConnector && this.sternConnector) {
        bowMooringBitt = this.bowConnector.getConnectTarget();
        sternMooringBitt = this.sternConnector.getConnectTarget();
        if (bowMooringBitt && sternMooringBitt) {
          bowBittCoords = bowMooringBitt.getCoordinates();
          sternBittCoords = sternMooringBitt.getCoordinates();
        }
      }
    }
    if (bowBittCoords && sternBittCoords) {
      bowBittCoords.x = Array.isArray(bowBittCoords.x)
        ? bowBittCoords.x[0]
        : bowBittCoords.x;
      bowBittCoords.y = Array.isArray(bowBittCoords.y)
        ? bowBittCoords.y[0]
        : bowBittCoords.y;
      sternBittCoords.x = Array.isArray(sternBittCoords.x)
        ? sternBittCoords.x[0]
        : sternBittCoords.x;
      sternBittCoords.y = Array.isArray(sternBittCoords.y)
        ? sternBittCoords.y[0]
        : sternBittCoords.y;

      var point1 = turf.point([bowBittCoords.x, bowBittCoords.y]);
      var point2 = turf.point([sternBittCoords.x, sternBittCoords.y]);

      var bearing = turf.bearing(point1, point2);
      var bearingToAzimuth = turf.bearingToAzimuth(bearing);
      var compensated = shipUtils.getHeading(bearingToAzimuth);
      return compensated;
    } else {
      return 0;
    }
  }

  updateBuffer() {
    if (
      this.shipSpecification &&
      this.shipSpecification.coordinates &&
      this.shipSpecification._showBuffer
    ) {
      let points = shipUtils.getBufferPoints(this.shipSpecification);
      this.buffer.setCoordinates(points);
    }
  }

  _createShape() {
    if (this.shipSpecification && this.shipSpecification.coordinates) {
      var shipParts = [];

      //ADD BUFFER IF _showBuffer = true
      if (this.shipSpecification._showBuffer) {
        var buffer = shipUtils.getShipBuffer(this.shipSpecification);
        this.setBuffer(buffer);
        shipParts.push(buffer);
      }

      //Get the hull of the ship (this is the main part)
      var hullSkeleton = shipUtils.getShipSkeleton(this.shipSpecification);
      this.setHull(hullSkeleton);
      shipParts.push(hullSkeleton);

      var shipPoints = shipUtils.getPoints(this.shipSpecification);
      let sternShipPos = shipPoints[MOORINGBITTSTERN_POS];
      let bowShipPos = shipPoints[MOORINGBITTBOW_POS];
      let sternShipBitt = new maptalks.Marker(sternShipPos, {
        symbol: shipUtils.getShipBittStyle(),
      });
      let bowShipBitt = new maptalks.Marker(bowShipPos, {
        symbol: shipUtils.getShipBittStyle(),
      });

      //ANTENNA
      let antenna = new maptalks.Marker(this.shipSpecification.coordinates, {
        symbol: shipUtils.getShipAntennaStyle(),
      });
      shipParts.push(antenna);
      
      this.setShipAntenna(antenna);
      shipParts.push(bowShipBitt);
      shipParts.push(sternShipBitt);
      this.setBowShipBitt(bowShipBitt);
      this.setSternShipBitt(sternShipBitt);

      //ISPS FLAG
      var dimensions = shipUtils.getShipDimensions(this.shipSpecification);
      let ispsObj = shipUtils.getShipISPS(
        this.shipSpecification.coordinates,
        dimensions.distanceToFront,
        dimensions.distanceToBack,
        dimensions.distanceToLeft,
        dimensions.distanceToRight,
        this.shipSpecification.hdg,
        this.shipSpecification.isps
      );
      this.setISPS(ispsObj);
      shipParts.push(ispsObj);

      //HAZMAT FLAG
      let hazardousCargoObj = shipUtils.getShipHazardousCargo(
        this.shipSpecification.coordinates,
        dimensions.distanceToFront,
        dimensions.distanceToBack,
        dimensions.distanceToLeft,
        dimensions.distanceToRight,
        this.shipSpecification.hdg,
        this.shipSpecification.hazmat
      );
      this.setHazmat(hazardousCargoObj);
      shipParts.push(hazardousCargoObj);

      this.setGeometries(shipParts);

      if (this.highlighted) {
        this.addHighlight();
      } else {
        this.removeHighlight();
      }
    }
    this.fire("created_shape", {
      target: this,
    });
  }

  _updateShape() {
    if (this.shipSpecification) {
      if (
        this.shipSpecification.bowMooringBitt &&
        this.shipSpecification.sternMooringBitt &&
        this.shipSpecification.bowMooringBitt.Code &&
        this.shipSpecification.sternMooringBitt.Code &&
        !this.shipSpecification._absolutePositioning
      ) {
        let midpoint = this._getMooringBittsMidpoint();
        if (midpoint && midpoint.length > 0) {
          this.shipSpecification.coordinates = midpoint;
          this.shipSpecification.hdg = this._getHeadingFromMooringBitts();
        }
      }

      if (this.antenna) {
        this.antenna.setCoordinates(this.shipSpecification.coordinates);
      }

      let dimensions = shipUtils.getShipDimensions(this.shipSpecification);

      //Update the hull of the ship (this is the main part)
      if (this.hull) {
        let hullPoints = shipUtils.getPoints(this.shipSpecification);
        this.hull.setCoordinates(hullPoints);

        let sternShipPos = hullPoints[MOORINGBITTSTERN_POS];
        let bowShipPos = hullPoints[MOORINGBITTBOW_POS];

        if (this.bowShipBitt) {
          this.bowShipBitt.setCoordinates(bowShipPos);
        }

        if (this.sternShipBitt) {
          this.sternShipBitt.setCoordinates(sternShipPos);
        }
      }

      if (this.isps) {
        let ispsPoints = shipUtils.getShipISPSPoints(
          this.shipSpecification.coordinates,
          dimensions.distanceToFront,
          dimensions.distanceToBack,
          dimensions.distanceToLeft,
          dimensions.distanceToRight,
          this.shipSpecification.hdg
        );
        this.isps.setCoordinates(ispsPoints);
      }

      if (this.hazmat) {
        let hazmatPoints = shipUtils.getShipHazardousCargoPoints(
          this.shipSpecification.coordinates,
          dimensions.distanceToFront,
          dimensions.distanceToBack,
          dimensions.distanceToLeft,
          dimensions.distanceToRight,
          this.shipSpecification.hdg
        );
        this.hazmat.setCoordinates(hazmatPoints);
      }

      if (this.highlighted) {
        this.addHighlight();
      } else {
        this.removeHighlight();
      }

      // hdg should be calculated through mooring bitts
      if (
        !!this.shipSpecification?.bowMooringBitt?.Code &&
        !!this.shipSpecification?.sternMooringBitt?.Code
      ) {
        let hdg = this._getHeadingFromMooringBitts(
          this.shipSpecification.bowMooringBitt.Code,
          this.shipSpecification.sternMooringBitt.Code
        );
        this.shipSpecification.hdg = hdg;
      }

      this._findClosestZone();
    }
  }

  _findClosestZone() {
    // if the ship has defined bollards, the zone is calculated by the terminal closest to the bollards
    let closestZone = null;

    //find closest terminal if Mooring Bitts Mid point exists
    if (
      this.bowConnector &&
      this.sternConnector &&
      this.bowConnector._connTarget &&
      this.sternConnector._connTarget
    ) {
      let bowBittCoords = this.bowConnector._connTarget._coordinates;
      let sternBittCoords = this.sternConnector._connTarget._coordinates;

      if (!!bowBittCoords && !!sternBittCoords) {
        for (let entry of this.zonesManager.getZones()) {
          let zone = entry[1];
          if (zone && zone._drawnObject) {
            let bowPointContains = zone._drawnObject.containsPoint(
              bowBittCoords,
              1
            );
            if (bowPointContains) closestZone = zone;

            let sternPointContains = zone._drawnObject.containsPoint(
              sternBittCoords,
              1
            );
            if (sternPointContains) closestZone = zone;
          }
        }
      }
    } else {
      let shipCenter = this.getCenter();
      // update berth location after mooring bitts update, so that the hovered zone
      // is the one after repositioning the ship
      if (this.zonesManager && shipCenter) {
        //find closest terminal using ship center
        for (let entry of this.zonesManager.getZones()) {
          let zone = entry[1];
          if (zone && zone._drawnObject) {
            let zoneCenter = zone._drawnObject.getCenter();

            if (zoneCenter) {
              let shipInZone = zone._drawnObject.containsPoint(shipCenter, 1);
              if (shipInZone) closestZone = zone;
            }
          }
        }
      }
    }

    if (closestZone) {
      this.shipSpecification.berth_location = closestZone.Code;
    } else {
      this.shipSpecification.berth_location = null;
    }
  }

  _removeShipParts() {
    this.getGeometries().forEach((geom) => {
      if (geom) {
        geom.remove();
      }
    });
  }

  _getVectorFromMooringBittGroups(bowSpec, sternSpec) {
    let vector =
      this.shipSpecification && this.shipSpecification.invertVector ? -1 : 1; // default is 1
    if (
      bowSpec &&
      bowSpec.MooringBittGroups &&
      sternSpec &&
      sternSpec.MooringBittGroups
    ) {
      let sharedBittGroups = bowSpec.MooringBittGroups.filter((group) => {
        return sternSpec.MooringBittGroups.some((sternG) => {
          return sternG.id == group.id;
        });
      });
      if (sharedBittGroups.length == 1) {
        return sharedBittGroups[0].vector * vector;
      }
      if (bowSpec.MooringBittGroups.length > 0) {
        return bowSpec.MooringBittGroups[0].vector * vector;
      }
      if (sternSpec.MooringBittGroups.length > 0) {
        return sternSpec.MooringBittGroups[0].vector * vector;
      }
    }
    return vector;
  }

  _getTranslatedMidPoint(
    bowBittCoords,
    sternBittCoords,
    distance,
    vector,
    hdg
  ) {
    if (bowBittCoords && sternBittCoords) {
      bowBittCoords.x = Array.isArray(bowBittCoords.x)
        ? bowBittCoords.x[0]
        : bowBittCoords.x;
      bowBittCoords.y = Array.isArray(bowBittCoords.y)
        ? bowBittCoords.y[0]
        : bowBittCoords.y;
      sternBittCoords.x = Array.isArray(sternBittCoords.x)
        ? sternBittCoords.x[0]
        : sternBittCoords.x;
      sternBittCoords.y = Array.isArray(sternBittCoords.y)
        ? sternBittCoords.y[0]
        : sternBittCoords.y;

      let line = turf.lineString([
        [bowBittCoords.x, bowBittCoords.y],
        [sternBittCoords.x, sternBittCoords.y],
      ]);
      //HACK to flip direction accordingly to https://github.com/Turfjs/turf/issues/1451
      if (vector < 0) {
        vector = 1;
        hdg += 180;
      }
      let transformedLine = turf.transformTranslate(
        line,
        /*km*/
        distance * vector,
        hdg
      );
      let midpoint = turf.midpoint(
        transformedLine.geometry.coordinates[0],
        transformedLine.geometry.coordinates[1]
      );
      return midpoint.geometry.coordinates;
    } else {
      return [];
    }
  }

  /**
   * Midpoint for the specified zone
   */
  _getZoneMidpoint(zoneCode) {
    if (this.zonesManager) {
      let zone = this.zonesManager.getZoneByCode(zoneCode);
      if (zone && zone._drawnObject) {
        let center = zone._drawnObject.getCenter();
        return [center.x, center.y];
      }
    }
    return null;
  }

  /**
   * Midpoint needs the width. For this, we use DimC (one of the width measures of the ship OR the with indicated as a parameter)
   */
  _getMooringBittsMidpoint(bowMooringBittCode, sternMooringBittCode, width) {
    var midpoint, bowBittCoords, sternBittCoords;
    if (
      bowMooringBittCode &&
      sternMooringBittCode &&
      this.mooringBittsManager
    ) {
      //get midpoint from here
      //get hdg from spec, we haven't got the ship drawn yet
      let bowMooringBittSpec =
        this.mooringBittsManager.getMooringBittByCode(bowMooringBittCode);
      let sternMooringBittSpec =
        this.mooringBittsManager.getMooringBittByCode(sternMooringBittCode);
      if (!bowMooringBittSpec || !sternMooringBittSpec) {
        return [null, null];
      }
      let bowLocation = wkt.parse(bowMooringBittSpec.MapGeom);
      let bowCoords = bowLocation.coordinates;
      bowBittCoords = {
        x: bowCoords[0],
        y: bowCoords[1],
      };

      let sternLocation = wkt.parse(sternMooringBittSpec.MapGeom);
      let sternCoords = sternLocation.coordinates;
      sternBittCoords = {
        x: sternCoords[0],
        y: sternCoords[1],
      };
    } else {
      if (this.bowConnector && this.sternConnector) {
        let bowMooringBitt = this.bowConnector.getConnectTarget();
        let sternMooringBitt = this.sternConnector.getConnectTarget();
        if (bowMooringBitt && sternMooringBitt) {
          bowBittCoords = bowMooringBitt.getCoordinates();
          sternBittCoords = sternMooringBitt.getCoordinates();

          bowBittCoords.x = Array.isArray(bowBittCoords.x)
            ? bowBittCoords.x[0]
            : bowBittCoords.x;
          bowBittCoords.y = Array.isArray(bowBittCoords.y)
            ? bowBittCoords.y[0]
            : bowBittCoords.y;
          sternBittCoords.x = Array.isArray(sternBittCoords.x)
            ? sternBittCoords.x[0]
            : sternBittCoords.x;
          sternBittCoords.y = Array.isArray(sternBittCoords.y)
            ? sternBittCoords.y[0]
            : sternBittCoords.y;

          var point1 = turf.point([bowBittCoords.x, bowBittCoords.y]);
          var point2 = turf.point([sternBittCoords.x, sternBittCoords.y]);
          midpoint = turf.midpoint(point1, point2);
          midpoint = midpoint.geometry.coordinates;
          if (!bowMooringBittCode)
            bowMooringBittCode =
              this.bowConnector.getConnectTarget().properties.code;
          if (!sternMooringBittCode)
            sternMooringBittCode =
              this.sternConnector.getConnectTarget().properties.code;
        }
      }
    }

    let bowMooringBittSpec =
      this.mooringBittsManager.getMooringBittByCode(bowMooringBittCode);
    let sternMooringBittSpec =
      this.mooringBittsManager.getMooringBittByCode(sternMooringBittCode);

    let vector = this._getVectorFromMooringBittGroups(
      bowMooringBittSpec,
      sternMooringBittSpec
    );

    let hdg = this._getHeadingFromMooringBitts(
      bowMooringBittCode,
      sternMooringBittCode
    );

    let minWidth = 0;
    if (this.shipSpecification && this.shipSpecification.dimC && this.shipSpecification.dimD) {
      minWidth = (this.shipSpecification.dimC + this.shipSpecification.dimD) / 2;
    }
    if (width && width > 0) {
      minWidth = width;
    }

    if (Math.floor(hdg) > 180 || Math.floor(hdg) == 0) vector *= -1;

    let distance =
      ((this._bumpedDistance + minWidth) * WIDTH_MULTIPLICATION_FACTOR) / 1000;

    midpoint = this._getTranslatedMidPoint(
      bowBittCoords,
      sternBittCoords,
      distance,
      vector,
      hdg
    );

    return midpoint;
  }

  intersects(point) {
    if (this.hull) {
      var pt = turf.point(point);
      let polygonPoints = this.hull.getCoordinates();

      polygonPoints = polygonPoints.map((p) => {
        return p.map((c) => {
          return [c.x, c.y];
        });
      });
      var poly = turf.polygon(polygonPoints);
      return turf.booleanPointInPolygon(pt, poly);
    }
    return false;
  }

  intersectsWith(other) {
    if (this.hull && other.hull) {
      let polygon1Points = this.hull.getCoordinates();

      polygon1Points = polygon1Points.map((p) => {
        return p.map((c) => {
          return [c.x, c.y];
        });
      });
      var poly1 = turf.polygon(polygon1Points);

      let polygon2Points = other.hull.getCoordinates();
      polygon2Points = polygon2Points.map((p) => {
        return p.map((c) => {
          return [c.x, c.y];
        });
      });
      var poly2 = turf.polygon(polygon2Points);
      return turf.intersect(poly1, poly2);
    }
    return false;
  }

  /**
   * Get connect points if being connected by [ConnectorLine]{@link ConnectorLine}
   * @private
   * @return {Coordinate[]}
   */
  _getConnectPoints() {
    let bittAnchors = [];

    if (this.bowShipBitt) {
      bittAnchors.push(this.bowShipBitt.getCoordinates());
    }
    if (this.sternShipBitt) {
      bittAnchors.push(this.sternShipBitt.getCoordinates());
    }
    return bittAnchors;
  }

  /**
   * Removes (if any) highlight of the current ship
   *
   */
  removeHighlight() {
    if (this.hull && this.highlighted) {
      let style = shipUtils.getShipStyle(this.shipSpecification);
      this.hull.updateSymbol(style.symbol);
      this.highlighted = false;

      this.fire("ship_dehighlighted", {
        id: this.properties.id,
        ship: this,
      });
    }
  }

  /**
   * Adds highlight to the current ship
   *
   */
  addHighlight() {
    if (this.hull && !this.highlighted) {
      this.hull.updateSymbol({
        lineWidth: HIGHLIGHT_LINE_WIDTH,
        lineColor: HIGHLIGHT_LINE_COLOR,
      });
      this.highlighted = true;
      this.fire("ship_highlighted", {
        id: this.properties.id,
        ship: this,
      });
    }
  }

  remove() {
    super.remove();
    this.removeHighlight();
  }
}
