import DispatcherEvent from "@/helpers/dispatcherevent";
import mooringBittUtils from "@/helpers/map/mooringbitt_utils";

class MooringBittsManager {
  constructor() {
    this.events = {};
    this.mooringBitts = new Map();
  }

  clearMooringBitts() {
    this.mooringBitts.clear();
  }

  getMooringBitts() {
    return this.mooringBitts;
  }

  getMooringBittById(id) {
    return this.mooringBitts.get(id);
  }

  getMooringBittByCode(code) {
    let foundBitt = null;
    for (let value of this.mooringBitts) {
      let bitt = value[1];
      if (bitt.Code == code) {
        foundBitt = bitt;
        break;
      }
    }
    return foundBitt;
  }

  mooringBittExists(id) {
    return this.mooringBitts.has(id);
  }

  addMooringBitts(mooringBitts) {
    if (mooringBitts) {
      mooringBitts.forEach((bitt) => {
        this.addMooringBitt(bitt);
      });
    }
  }

  addMooringBitt(mooringBitt) {
    if (this.mooringBittExists(mooringBitt.Id)) {
      this.updateMooringBitt(mooringBitt);
    } else {
      this.mooringBitts.set(mooringBitt.Id, mooringBitt);
      this.dispatch("mooring_bitt_added", mooringBitt);
    }
  }

  updateMooringBitt(mooringBitt) {
    if (this.mooringBittExists(mooringBitt.Id)) {
      this.mooringBitts.set(mooringBitt.Id, mooringBitt);
      this.dispatch("mooring_bitt_updated", mooringBitt);
    }
  }

  clearAll() {
    if (this.mooringBitts) {
      for (let value of this.mooringBitts) {
        this.dispatch("mooring_bitt_removed", value[1]);
      }
      this.clearMooringBitts();
    }
  }

  /**
   * Global event mechanism
   */
  dispatch(eventName, data) {
    const event = this.events[eventName];
    if (event) {
      event.fire(data);
    }
  }

  on(eventName, callback) {
    let event = this.events[eventName];
    if (!event) {
      event = new DispatcherEvent(eventName);
      this.events[eventName] = event;
    }
    event.registerCallback(callback);
  }

  off(eventName, callback) {
    const event = this.events[eventName];
    if (event && event.callbacks.indexOf(callback) > -1) {
      event.unregisterCallback(callback);
      if (event.callbacks.length === 0) {
        delete this.events[eventName];
      }
    }
  }

  resetStyle(hardReset) {
    if (this.mooringBitts) {
      for (let value of this.mooringBitts) {
        let bitt = value[1];
        if (bitt && bitt._drawnObject) {
          if (hardReset) {

            bitt._drawnObject.updateSymbol(
              mooringBittUtils.getMooringBittStyle()
            );
          } else {
            bitt._drawnObject.updateSymbol({
              markerLineColor: {
                // use heat value in property
                property: "borderColor",
                type: "identity",
              },
              markerLineWidth: 1,
            });
          }
        }
      }
    }
  }
}

export default MooringBittsManager;
